import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyPageSegmentPagePathLog, SearchMonthlyPageSegmentPagePathLog } from '../../Types/MonthlyPageSegmentPagePathLog'


export const getMonthlyPageSegmentPagePathLogList = async (searchParams: SearchMonthlyPageSegmentPagePathLog | undefined) => {
    const { data } = await axios.get('/api/monthly_page_segment_page_path_logs', { params: searchParams })
    return data
}

export const useMonthlyPageSegmentPagePathLogList = (searchParams: SearchMonthlyPageSegmentPagePathLog | undefined): UseQueryResult<MonthlyPageSegmentPagePathLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyPageSegmentPagePathLogList', searchParams],
        queryFn: () => getMonthlyPageSegmentPagePathLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
