import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyPagePathLog, SearchMonthlyPagePathLog } from '../../Types/MonthlyPagePathLog'


export const getMonthlyPagePathLogList = async (searchParams: SearchMonthlyPagePathLog | undefined) => {
    const { data } = await axios.get('/api/monthly_page_path_logs', { params: searchParams })
    return data
}

export const useMonthlyPagePathLogList = (searchParams: SearchMonthlyPagePathLog | undefined): UseQueryResult<MonthlyPagePathLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyPagePathLogList', searchParams],
        queryFn: () => getMonthlyPagePathLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
