import { Button } from "@mui/material"
import { usePageSegmentSelectOptions } from "../../Hooks/User/PageSegment"
import { MultiSelect } from "../Ui/Form"
import { Link } from "react-router-dom"

export const PageSegmentMultiSelect = ({ siteId, name, placeholder='ページ分類を選択してください', onChange }: { siteId: number, name: string, placeholder?: string, onChange?: (newValue: number | string) => void }) => {
    const { options, isLoading, isError } = usePageSegmentSelectOptions(siteId)

    if (isLoading) return <span className="text-xs">Loading...</span>
    if (isError) return <span className="text-xs">Error loading</span>

    return (
        options && options.length > 0 ? (
            <MultiSelect name={name} options={options} placeholder={placeholder} onChange={onChange} isClearable={true} />
        ) : (
            <div className="text-xs flex items-center justify-between">
                <p>ページ分類は未設定です</p>
                <Link to={`/page_segments/create`}>
                    <Button size="small" variant="outlined">ページ分類を設定をする</Button>
                </Link>
            </div>
        )
    )
}