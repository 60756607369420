import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { ConversionPagePathSetting } from '../../Types/ConversionPagePathSetting'

export const getConversionPagePathSettingList = async (site_id: string) => {
    const { data } = await axios.get(`/api/conversion_page_path_settings/${site_id}`)
    return data
}

export const createConversionPagePathSetting = async (data: ConversionPagePathSetting): Promise<AxiosResponse> => {
    return await axios.post('/api/conversion_page_path_settings', data)
}

// use react-query
export const useConversionPagePathSettingList = (site_id: string): UseQueryResult<ConversionPagePathSetting[]> => {
    return useQuery({
        queryKey: ['conversionPagePathSettingList', site_id],
        queryFn: () => getConversionPagePathSettingList(site_id),
    })
}
