import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { ConversionEventSetting } from '../../Types/ConversionEventSetting'

export const getConversionEventSettingList = async (site_id: string) => {
    const { data } = await axios.get(`/api/conversion_event_settings/${site_id}`)
    return data
}

export const createConversionEventSetting = async (data: ConversionEventSetting): Promise<AxiosResponse> => {
    return await axios.post('/api/conversion_event_settings', data)
}

// use react-query
export const useConversionEventSettingList = (site_id: string): UseQueryResult<ConversionEventSetting[]> => {
    return useQuery({
        queryKey: ['conversionEventSettingList', site_id],
        queryFn: () => getConversionEventSettingList(site_id),
    })
}
