import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { PageSegment, SearchPageSegment } from '../../Types/PageSegment'
import { useMemo } from 'react'

export const getPageSegmentList = async (searchParams: SearchPageSegment | undefined) => {
    const { data } = await axios.get('/api/page_segments', { params: searchParams })
    return data
}

export const getPageSegment = async (id: string) => {
    const { data } = await axios.get(`/api/page_segments/${id}`)
    return data
}

export const updatePageSegment = async (data: PageSegment): Promise<AxiosResponse> => {
    return await axios.patch('/api/page_segments', data)
}

export const createPageSegment = async (data: PageSegment): Promise<AxiosResponse> => {
    return await axios.post('/api/page_segments', data)
}

// use react-query
export const usePageSegmentList = (searchParams: SearchPageSegment | undefined): UseQueryResult<PageSegment[]> => {
    return useQuery({
        queryKey: ['getPageSegmentList', searchParams],
        queryFn: () => getPageSegmentList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const usePageSegmentSelectOptions = (site_id: number) => {
    const { data: pageSegmentList, ...queryInfo } = usePageSegmentList({ site_id: site_id })

    const options = useMemo(() => {
        if (!pageSegmentList) return []

        return pageSegmentList.map((pageSegment: PageSegment) => (
            {
                label: pageSegment.name,
                value: pageSegment.id,
            }
        ))
    }, [pageSegmentList])

    return { options, ...queryInfo }
}
