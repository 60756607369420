import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { H1 } from "../../../Ui/H1"
import { MainContent } from "../../../Ui/MainContent"
import { Input, Label } from "../../../Ui/Form"
import { SubmitButton } from "../../../Ui/Button"
import { PageSegment, Rule } from "../../../../Types/PageSegment"
import { PageSegmentParts } from "../../../Molecules/PageSegmentRule"
import { useSite } from "../../../../Providers/Site"
import { Message } from "../../../Ui/Message"
import { useEffect, useState } from "react"
import { Loading } from "../../../Ui/Loading"
import { useNavigate } from "react-router-dom"
import { createPageSegment } from "../../../../Hooks/User/PageSegment"
import TagIcon from '@mui/icons-material/Tag'

export const PageSegmentCreate = () => {
    const navigate = useNavigate()
    const { siteId } = useSite()
    const [isLoading, setIsLoading] = useState(false)
    const newSegments = [[{ path: "", rule_type_id: 1 }]] // 初回に１行表示させる

    const useFormMethods = useForm<PageSegment>()
    const { handleSubmit, getValues, setValue, setError } = useFormMethods
    
    const onSubmit: SubmitHandler<PageSegment> = (data) => {
        setIsLoading(true)
        
        createPageSegment(data)
            .then(() => {
                navigate('/page_segments', { state: 'サイト分類を登録しました' })
            }).catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                // if (errors.company_id) setError('company_id', { type: 'custom', message: errors.company_id.join('/') })
                // if (errors.is_active) setError('is_active', { type: 'custom', message: errors.is_active.join('/') })
                // if (errors.name) setError('name', { type: 'custom', message: errors.name.join('/') })
                // if (errors.email) setError('email', { type: 'custom', message: errors.email.join('/') })
                // if (errors.password) setError('password', { type: 'custom', message: errors.password.join('/') })
            })
    }

    useEffect(() => {
        setValue('site_id', Number(siteId))
    }, [siteId])

    if (!siteId) {
        return <Message message="ヘッダーのプルダウンより対象のサイトを選択してください" />
    }
    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<TagIcon />}>ページ分類</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label isRequire={true}>ページ分類名</Label>
                        <Input name="name" type="text" />
                        
                        <Label isRequire={true}>ルール</Label>
                        <PageSegmentParts pageSegments={newSegments} />

                        <div className="mt-8">
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}