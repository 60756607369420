import * as React from 'react'
import { TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/ja' // 日本語ロケールのインポート
import { Controller, useFormContext } from 'react-hook-form'

// 日本語ロケールを設定
dayjs.locale('ja')

const YearMonthPicker = ({ name }: { name: string }) => {
  const { control, formState: { errors }, setValue: formSetValue, watch } = useFormContext()
  
  // フォームからの値を監視して初期値を設定
  const formValue = watch(name)
  
  // 日付が選択されていない場合、nullとして扱う
  const [value, setValue] = React.useState<string | null>(formValue || null)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name={name}
            label="年月を選択"
            views={['year', 'month']} // 年と月だけを選択
            minDate={dayjs('2020-01-01')}
            maxDate={dayjs('2030-12-31')}
            value={value ? dayjs(value) : null} // 空の場合はnullにする
            onChange={(newValue) => {
              if (newValue) {
                const formattedValue = newValue.format('YYYY-MM')
                formSetValue(name, formattedValue) // フォームの値を更新
                setValue(formattedValue) // 状態も更新
              } else {
                formSetValue(name, null) // 値が選択されなかった場合はnullに設定
                setValue(null) // 状態もnullに設定
              }
            }}
            slots={{
              textField: TextField, // 直接 TextField を指定
            }}
            slotProps={{
              textField: {
                fullWidth: true, // 必要に応じてTextFieldのpropsをここに渡す
                InputProps: {
                  placeholder: value ? `${dayjs(value).format('YYYY年MM月')}` : '', // valueがnullの時は空白にする
                },
              },
            }}
            // フォーマットの設定
            format="YYYY年MM月" // 年月を「2024年10月」の形式で表示
          />
        </LocalizationProvider>
      )}
    />
  )
}

export default YearMonthPicker
