import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllPageSegmentLandingPageLog, SearchAllPageSegmentLandingPageLog } from '../../Types/AllPageSegmentLandingPageLog'


export const getAllPageSegmentLandingPageLogList = async (searchParams: SearchAllPageSegmentLandingPageLog | undefined) => {
    const { data } = await axios.get('/api/grouped_page_segment_landing_page_logs', { params: searchParams })
    return data
}

export const useAllPageSegmentLandingPageLogList = (searchParams: SearchAllPageSegmentLandingPageLog | undefined): UseQueryResult<AllPageSegmentLandingPageLog[]> => {
    return useQuery({
        queryKey: ['getAllPageSegmentLandingPageLogList', searchParams],
        queryFn: () => getAllPageSegmentLandingPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
