import { Card, CardContent, useTheme } from "@mui/material"
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart as RechartPieChart,
    Pie,
    Cell,
    BarChart as RechartBarChart,
    TooltipProps,
    AreaChart,
    Area,
  } from 'recharts'

import { FC } from "react"
import { BarAndLineChartOptionProps, PieChartOptionProps } from "../../Interfaces/RechartOption"
import { roundWithScale } from "../../Utils/Math"

export const LinerRateBarChartWithLabel = ({ ...props }: { 
    rate: number, 
    mainColor?: string, 
    bgColor?: string, 
    height?: number, 
    labelTop?: number, 
    gap?: number, 
    labelColor?: string 
}) => {
    const mainColor = props.mainColor || '#1567db'
    const bgColor = props.bgColor || '#eee'
    const width = props.rate < 100 ? props.rate : 100
    const height = props.height || 10
    const labelTop = props.labelTop || -2
    const gap = props.gap || 20
    const labelColor = props.labelColor || mainColor

    return (
        <div className="line-bar-chart-with-label" style={{ gap: gap }}>
            <div className="bar">
                <div className="background" style={{ height: height, backgroundColor: bgColor }}></div>
                <div className="data" style={{ height: height, backgroundColor: mainColor, width: `${width}%`, top: `-${height}px` }}></div>
            </div>
            <div className="label" style={{ top: labelTop, color: labelColor }}>
                {props.rate}%
            </div>
        </div>
    )
}

export const LinerValueBarChartWithLabel = ({ ...props }: { 
    value: number, 
    totalValue: number,
    mainColor?: string, 
    bgColor?: string, 
    height?: number, 
    labelTop?: number, 
    labelWidth?: number,
    gap?: number, 
    labelColor?: string 
}) => {
    const mainColor = props.mainColor || '#1567db'
    const bgColor = props.bgColor || '#eee'
    const rate = (props.value / props.totalValue) * 100
    const width = rate < 100 ? rate : 100
    const height = props.height || 10
    const labelTop = props.labelTop || -2
    const gap = props.gap || 20
    const labelColor = props.labelColor || mainColor
    const labelWidth = props.labelWidth || 40

    return (
        <div className="line-bar-chart-with-label" style={{ gap: gap }}>
            <div className="bar">
                <div className="background" style={{ height: height, backgroundColor: bgColor }}></div>
                <div className="data" style={{ height: height, backgroundColor: mainColor, width: `${width}%`, top: `-${height}px` }}></div>
            </div>
            <div className="label" style={{ top: labelTop, color: labelColor, width: labelWidth }}>
                {props.value.toLocaleString()}
            </div>
        </div>
    )
}

interface MonthlyAreaChartProps {
    data: { yearMonth: string, value: number }[]
    label: string
    isRate?: boolean
}
export const MonthlyAreaChart: FC<MonthlyAreaChartProps> = ({ data, label, isRate=false }) => {
    if (!data) return

    return (
        <ResponsiveContainer width="100%" height="100%" className="-mt-8">
            <AreaChart data={data}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="10%" stopColor="#1567db" stopOpacity={0.3}/>
                        <stop offset="90%" stopColor="#1567db" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="yearMonth" hide={true} />
                <YAxis hide={true} />
                <Tooltip formatter={(value) => [isRate ? `${value.toLocaleString()}%` : value.toLocaleString(), label]} />
                <Area type="monotone" dataKey="value" stroke="#1567db" fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>
        </ResponsiveContainer>
    )
}

interface MonthlyOnlyLastStackBarChartProps {
    data: { yearMonth: string, value1: number, value2: number }[]
    tooltipLabel: string
    baseColor: string
    stackColor: string
    targetYearMonth: string
}
// デザインの関係上、当月は「value1+value2」で良いが前月以前のデータはvalue2を「value1」として見せる必要があります...（CustomTooltip()で分岐させている）
export const MonthlyOnlyLastStackBarChart: FC<MonthlyOnlyLastStackBarChartProps> = ({ data, tooltipLabel, baseColor, stackColor, targetYearMonth }) => {
    const CustomTooltip: FC<TooltipProps<any, any>> = ({ active, payload }) => {
        if (active && payload && payload.length) {
            // 対象年月のデータの場合
            if (payload[0]?.payload.yearMonth === targetYearMonth) {
                return (
                    <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                        <p className="label">{`${payload[0].payload.yearMonth}`}</p>
                        <p className="intro">{`${tooltipLabel}: ${payload[0].value.toLocaleString()}`}</p>
                        <p className="intro">{`月末予測: ${(payload[0].value + payload[1].value).toLocaleString()}(+${payload[1].value.toLocaleString()})`}</p>
                    </div>
                )
            }
            // それ以外のデータの場合
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label">{`${payload[0].payload.yearMonth}`}</p>
                    <p className="intro">{`${tooltipLabel}: ${payload[1].value.toLocaleString()}`}</p>
                </div>
            )
        }
        return null
    }

    return (
        <ResponsiveContainer width="100%" height="53%">
            <RechartBarChart data={data} margin={{ top: 20, right: 0, left: 0, bottom: 20 }}>
                <Tooltip  
                    cursor={{ fill: '#efefef' }}
                    content={<CustomTooltip />}
                />

                <Bar dataKey="value1" stackId="A" barSize={8}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-session-${index}`} fill={entry.yearMonth === targetYearMonth ? baseColor : '#ddd'} />
                    ))}
                </Bar>
                <Bar dataKey="value2" stackId="A" radius={[15, 15, 0, 0]} barSize={8}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-prediction-${index}`} fill={entry.yearMonth === targetYearMonth ? stackColor : '#ddd'} />
                    ))}
                </Bar>
            </RechartBarChart>
        </ResponsiveContainer>
    )
}

export const BarAndLineChart: FC<BarAndLineChartOptionProps> = ({
    width, 
    height, 
    data, 
    xAxisDataKey,
    barDataKey, 
    lineDataKey, 
    barName, 
    lineName, 
    isLineRateData=false,
    top=20,
    right=10, 
    left=10
}) => {
    const theme = useTheme()
    const numberFormatter = new Intl.NumberFormat('ja-JP')

    return (
        <Card style={{ width: width }}>
            <CardContent sx={{ width: '100%', height: height}}>
                {(data && data.length > 0) ? (
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            data={data}
                            margin={{
                                top: top,
                                right: right,
                                left: left,
                            }}
                        >
                            <CartesianGrid stroke={theme.palette.mode === 'dark' ? '#333' : '#f5f5f5'} />
                            <XAxis
                                dataKey={xAxisDataKey}
                                stroke={theme.palette.text.primary}
                                tickMargin={10}
                            />
                            <YAxis 
                                yAxisId="left"
                                // label={{ value: 'セッション', angle: -90, position: 'insideLeft' }}
                                tickFormatter={(value) => numberFormatter.format(value as number)}
                                stroke={theme.palette.text.primary}
                            />
                            <YAxis 
                                yAxisId="right"
                                orientation="right"
                                // label={{ value: 'CV', angle: -90, position: 'outsideRight' }}
                                tickFormatter={(value) => {
                                    if (isLineRateData) {
                                        return roundWithScale(value, 2).toString() + '%'
                                    } else {
                                        return numberFormatter.format(value as number)
                                    }
                                }}
                                stroke={theme.palette.text.primary}
                            />
                            <Tooltip
                                contentStyle={{
                                    backgroundColor: theme.palette.background.default,
                                    color: theme.palette.text.primary,
                                }}
                                formatter={(value, name) => {
                                    if (isLineRateData && name == lineName) {
                                        return [roundWithScale(value as number, 2).toString() + '%', `${name}`]
                                    } else {
                                        return [numberFormatter.format(value as number), `${name}`]
                                    }
                                }}
                                labelFormatter={(label) => `${label}`}
                            />
                            <Legend 
                                layout="horizontal"
                                wrapperStyle={{
                                    bottom: -10,
                                }}
                            />
                            <Bar
                                yAxisId="left"
                                dataKey={barDataKey} 
                                barSize={25} 
                                // fill={theme.palette.primary.main} 
                                fill="#4285F4"
                                name={barName} 
                            />
                            <Line
                                yAxisId="right"
                                type="monotone" 
                                dataKey={lineDataKey} 
                                // stroke={theme.palette.secondary.main} 
                                stroke={'#EA4235'}
                                dot={{ fill: '#EA4235', r: 3 }}
                                name={lineName} 
                                strokeWidth={3} 
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                ) : (
                    <div style={{ height: height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        No Data
                    </div>
                )}
            </CardContent>
        </Card>
    )
}
  
export const PieChart: FC<PieChartOptionProps> = ({
        width,
        height,
        margin,
        cx,
        cy,
        outerRadius,
        data
    }) => {

    const theme = useTheme()
    const chartColors = theme.chart.colors;

    return (
        <div className="flex justify-center">
            <RechartPieChart width={width} height={height - margin}>
                <Pie
                    data={data}
                    cx={cx}
                    cy={cy}
                    labelLine={false}
                    outerRadius={outerRadius}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
                    ))}
                </Pie>
                <Tooltip 
                    formatter={(value: number) => value.toLocaleString()}
                />
                <Legend />
            </RechartPieChart>
        </div>
    )
}

const renderCustomizedLabel = ({ percent, name }: any) => {
    if (percent < 0.02) return null // しきい値以下の場合、ラベルを表示しない
  
    return (
        name
    )
}