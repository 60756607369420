import { CHART_LIMIT } from "../../config"
import { EChartMixLineBar } from "../Ui/EChart"
import { formatRanking, getSumBarChartDataByDate, getAverageRateLineChartData, getStackBarChartDataPerCategory } from "./DailyLogEChart"

export const MonthlySessionLineBarChart = ({ data }: { data: any[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.year_month).getTime() - new Date(b.year_month).getTime())
    
    // 日付毎のセッションデータを取得
    const { date, barData, barChartSize } = getSumBarChartDataByDate(sortedData, 'セッション', 'year_month', 'sessions')

    // 日付毎の新規ユーザー率データを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, '新規ユーザー率', 'new_user_rate', 'year_month')

    const yAxis = [
        {
            type: 'value',
            name: barData.name,
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: lineData.name,
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={date} series={[barData, lineData]} yAxis={yAxis} />
    )
}

export const MonthlyConversionLineBarChart = ({ data }: { data: any[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.year_month).getTime() - new Date(b.year_month).getTime())
    
    // 日付毎のセッションデータを取得
    const { date, barData, barChartSize } = getSumBarChartDataByDate(sortedData, 'CV', 'year_month', 'conversions')

    // 日付毎の新規ユーザー率データを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, 'CVR', 'conversion_rate', 'year_month')

    const yAxis = [
        {
            type: 'value',
            name: barData.name,
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: lineData.name,
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={date} series={[barData, lineData]} yAxis={yAxis} />
    )
}


export const MonthlyCategorySessionLineBarChart = ({ data, category, targetColumn }: { data: any[] | undefined, category: string[], targetColumn: string }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.year_month).getTime() - new Date(b.year_month).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.year_month)))

    // チャネル毎のデータを取得
    const { barData, barChartSize} = getStackBarChartDataPerCategory(sortedData, category, targetColumn, 'sessions', xAxisData, 'year_month')

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, '新規ユーザー率', 'new_user_rate', 'year_month')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'セッション',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: '新規ユーザー率',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const MonthlyCategoryConversionLineBarChart = ({ data, category, targetColumn }: { data: any[] | undefined, category: string[], targetColumn: string }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.year_month).getTime() - new Date(b.year_month).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.year_month)))

    // チャネル毎のデータを取得
    const { barData, barChartSize} = getStackBarChartDataPerCategory(sortedData, category, targetColumn, 'conversions', xAxisData, 'year_month')

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, 'CVR', 'conversion_rate', 'year_month')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'CV',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: 'CVR',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const MonthlySummarizeSessionLineBarChart = ({ data, targetColumn }: { data: any[] | undefined, targetColumn: string }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.year_month).getTime() - new Date(b.year_month).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.year_month)))

    // 上位+その他でdataをフォーマット
    const formattedData = formatRanking(sortedData, targetColumn, 'sessions', CHART_LIMIT, 'year_month')
    
    // ページ分類をユニークで取得
    const pageSegments = Array.from(new Set(formattedData.map(item => item.page_segment_name))).sort((a, b) => {
        if (a === 'その他') return 1 // aがその他の場合、後ろに移動
        if (b === 'その他') return -1 // bがその他の場合、前に移動
        return 0 // それ以外の順序は変更しない
    })

    // ページ分類毎のデータを取得
    const { barData, barChartSize } = getStackBarChartDataPerCategory(formattedData, pageSegments, 'page_segment_name', 'sessions', xAxisData, 'year_month')

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, '新規ユーザー率', 'new_user_rate', 'year_month')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'セッション',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: '新規ユーザー率',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const MonthlySummarizeConversionLineBarChart = ({ data, targetColumn }: { data: any[] | undefined, targetColumn: string }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.year_month).getTime() - new Date(b.year_month).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.year_month)))

    // 上位+その他でdataをフォーマット
    const formattedData = formatRanking(sortedData, targetColumn, 'conversions', CHART_LIMIT, 'year_month')
    
    // ページ分類をユニークで取得
    const pageSegments = Array.from(new Set(formattedData.map(item => item.page_segment_name))).sort((a, b) => {
        if (a === 'その他') return 1 // aがその他の場合、後ろに移動
        if (b === 'その他') return -1 // bがその他の場合、前に移動
        return 0 // それ以外の順序は変更しない
    })

    // ページ分類毎のデータを取得
    const { barData, barChartSize } = getStackBarChartDataPerCategory(formattedData, pageSegments, 'page_segment_name', 'conversions', xAxisData, 'year_month')

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, 'CVR', 'conversion_rate', 'year_month')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'CV',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: 'CVR',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}