
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { Rule } from "../../Types/PageSegment"
import { Button, Card, CardContent, Chip, IconButton } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'

interface PageSegmentPartsProps {
    pageSegments: Rule[][]
}
export const PageSegmentParts: FC<PageSegmentPartsProps> = ({ pageSegments }) => {
    const [segments, setSegments] = useState<Rule[][]>(pageSegments)

    const handleAddSegment = () => {
        setSegments((prevSegments) => [...prevSegments, [{ path: "", rule_type_id: 1 }]])
    }

    useEffect(() => {
        setSegments(pageSegments)
    }, [pageSegments])

    if (!segments || segments.length === 0) return <div>Loading...</div>
    return (
        <div>
            {segments.map((rules, segmentIndex) => (
                <Rules key={`rule-${segmentIndex}`} segmentIndex={segmentIndex} rules={rules} setSegments={setSegments} />
            ))}
    
            <Button type="button" onClick={handleAddSegment}>
                AND条件を追加
            </Button>
        </div>
    )
}

interface SegmentRulesProps {
    segmentIndex: number
    rules: Rule[]
    setSegments: Dispatch<SetStateAction<Rule[][]>>
}
export const Rules: FC<SegmentRulesProps> = ({ rules, segmentIndex, setSegments }) => {
    const { register, setValue, getValues } = useFormContext()

    const handleAddRule = () => {
        const currentRules = getValues(`rules.${segmentIndex}`) || []
        const newRule = { path: "", rule_type_id: 1 }
        const updatedRules = [...currentRules, newRule]

        setValue(`rules.${segmentIndex}`, updatedRules)

        setSegments((prevSegments) => {
            const updatedSegments = [...prevSegments]
            updatedSegments[segmentIndex] = updatedRules
            return updatedSegments
        })
    }

    const handleRemoveRule = (segmentIndex: number, ruleIndex: number) => {
        const currentRules = getValues(`rules.${segmentIndex}`)
        const updatedRules = currentRules.filter((_: any, index: number) => index !== ruleIndex)

        setSegments((prevSegments) => {
            let updatedSegments = [...prevSegments]

            // 更新されたルールが空であれば、そのセグメント自体を削除する
            if (updatedRules.length === 0) {
                updatedSegments = updatedSegments.filter((_, index) => index !== segmentIndex)
            } else {
                updatedSegments[segmentIndex] = updatedRules
            }

            setValue(`rules`, updatedSegments)

            return updatedSegments
        })
    }

    const handleChange = (name: string, value: string) => {
        setValue(name, value)
    }

    if (rules.length == 0) return ''
    return (
        <>
            {segmentIndex != 0 && (
                <Chip key={segmentIndex} label="かつ" size="small" sx={{ marginBottom: "5px", marginLeft: "172px"}} />
            )}
            <Card key={`card-${segmentIndex}`} sx={{ marginBottom: '10px' }}>
                <CardContent>
                    {rules.map((rule, ruleIndex) => (
                        <Fragment key={`rule-fragment-${segmentIndex}-${ruleIndex}`}>
                            {ruleIndex != 0 && (
                                <Chip key={`chip-${segmentIndex}-${ruleIndex}`} label="または" size="small" sx={{ marginBottom: "5px", marginLeft: "172px"}} />
                            )}
                            <div key={`rule-${segmentIndex}-${ruleIndex}`} style={{ marginBottom: "5px" }}>
                                <input
                                    {...register(`rules.${segmentIndex}.${ruleIndex}.path`)}
                                    defaultValue={rule.path}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-96 inline-flex p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                />
                                <select
                                    {...register(`rules.${segmentIndex}.${ruleIndex}.rule_type_id`)}
                                    defaultValue={rule.rule_type_id}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                >
                                    <option value={1}>次と完全一致</option>
                                    <option value={2}>含む</option>
                                    <option value={3}>先頭が一致</option>
                                    <option value={4}>最後が一致</option>
                                    <option value={5}>含まない</option>
                                </select>

                                <IconButton onClick={() => handleRemoveRule(segmentIndex, ruleIndex)}><DeleteIcon /></IconButton>
                            </div>
                        </Fragment>
                    ))}
                    <Button type="button" onClick={handleAddRule}>
                        OR条件を追加
                    </Button>
                </CardContent>
            </Card>
        </>
    )    

}
