import { getYear } from "date-fns";

export const Footer = () => {
    const date = new Date()
    const year = getYear(date)

    return (
        <footer className="py-5 bg-gray-50 dark:bg-gray-800">
            <div className="w-full px-4 mx-auto max-w-8xl">
                <span className="block text-center text-gray-600 dark:text-gray-400 font-">
                    © {year} Site Analyzer All Rights Reserved.
                </span>
            </div>
        </footer>
    );
}