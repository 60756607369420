import axios, { AxiosResponse } from 'axios'
import { GAProperty } from '../../Types/GAProperty'

export const getGAProperty = async (id: string) => {
    const { data } = await axios.get(`/api/ga_properties/${id}`)
    return data
}

export const getGAPropertyBySiteId = async (site_id: string) => {
    const { data } = await axios.get(`/api/ga_properties_site_id/${site_id}`)
    return data
}

export const updateGAProperty = async (data: GAProperty): Promise<AxiosResponse> => {
    return await axios.patch('/api/ga_properties', data)
}
