import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { User } from '../../Types/User'
import { useMemo } from 'react'
import { Role } from '../../Types/Role'

export const getRoleList = async () => {
    const { data } = await axios.get('/api/roles')
    return data
}

// use react-query
export const useRoleList = (): UseQueryResult<Role[]> => {
    return useQuery({
        queryKey: ['roleList'],
        queryFn: getRoleList,
    })
}

export const useRoleSelectOptions = () => {
    const { data: roleList, ...queryInfo } = useRoleList()

    const selectOptions = useMemo(() => {
        if (!roleList) return []

        return roleList.map((role: Role) => (
            {
                value: role.id,
                label: role.name,
            }
        ))
    }, [roleList])

    return { selectOptions, ...queryInfo }
}