import { roundWithScale } from "../../Utils/Math";
import { EChartMixLineBar } from "../Ui/EChart";
import { CHANNEL_GROUPS, CHART_LIMIT, DEVICE_CATEGORIES } from "../../config";
import { GAChannelGroupLog } from "../../Types/GAChannelGroupLog";
import { GADeviceCategoryLog } from "../../Types/GADeviceCategoryLog";
import { PageSegmentLandingPageLog } from "../../Types/PageSegmentLandingPageLog";
import { PageSegmentPagePathLog } from "../../Types/PageSegmentPagePathLog";

interface LineBarChartProps {
    data: any[] | undefined
    keyDate: string
    barChartLabel: string
    barChartValueColumn: string
    lineChartLabel: string
    lineChartValueColumn: string
}
export const LineBarChart = ({ data, keyDate, barChartLabel, barChartValueColumn, lineChartLabel, lineChartValueColumn }: LineBarChartProps) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a[keyDate]).getTime() - new Date(b[keyDate]).getTime())
    
    // 日付毎のセッションデータを取得
    const { date, barData, barChartSize } = getSumBarChartDataByDate(sortedData, barChartLabel, keyDate, barChartValueColumn)

    // 日付毎の新規ユーザー率データを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, lineChartLabel, lineChartValueColumn, keyDate)

    const yAxis = [
        {
            type: 'value',
            name: barData.name,
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: lineData.name,
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={date} series={[barData, lineData]} yAxis={yAxis} />
    )
}

const formatDataByDate = (data: any, keyColumn: string, valueColumn: string) => {
    const result: number[] = []

    data.forEach(item => {
        const keyDate = item[keyColumn]
        const value = item[valueColumn]

        if (!result[keyDate]) {
            result[keyDate] = 0
        }

        result[keyDate] += value        
    })

    const formattedData = Object.entries(result).map(([date, value]) => ({
        [keyColumn]: date,
        [valueColumn]: value,
    }))
    
    return formattedData
}

export const DailyChannelGroupSessionLineBarChart = ({ data }: { data: GAChannelGroupLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.date)))

    // チャネル毎のデータを取得
    const { barData, barChartSize} = getStackBarChartDataPerCategory(sortedData, CHANNEL_GROUPS, 'session_default_channel_group', 'sessions', xAxisData)

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, '新規ユーザー率', 'new_user_rate')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'セッション',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: '新規ユーザー率',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const DailyChannelGroupConversionLineBarChart = ({ data }: { data: GAChannelGroupLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.date)))

    // チャネル毎のデータを取得
    const { barData, barChartSize} = getStackBarChartDataPerCategory(sortedData, CHANNEL_GROUPS, 'session_default_channel_group', 'conversions', xAxisData)

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, 'CVR', 'conversion_rate')
    

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'CV',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: 'CVR',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const DailyDeviceCategorySessionLineBarChart = ({ data }: { data: GADeviceCategoryLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.date)))

    // デバイス毎のデータを取得
    const { barData, barChartSize} = getStackBarChartDataPerCategory(sortedData, DEVICE_CATEGORIES, 'device_category', 'sessions', xAxisData)

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, '新規ユーザー率', 'new_user_rate')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'セッション',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: '新規ユーザー率',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const DailyDeviceCategoryConversionLineBarChart = ({ data }: { data: GADeviceCategoryLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.date)))

    // デバイス毎のデータを取得
    const { barData, barChartSize} = getStackBarChartDataPerCategory(sortedData, DEVICE_CATEGORIES, 'device_category', 'conversions', xAxisData)

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, 'CVR', 'conversion_rate')
    

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'CV',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: 'CVR',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const DailyPageSegmentLPSessionLineBarChart = ({ data }: { data: PageSegmentLandingPageLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.date)))

    // 上位+その他でdataをフォーマット
    const formattedData = formatRanking(sortedData, 'page_segment_name', 'sessions', CHART_LIMIT, 'date')
    
    // ページ分類をユニークで取得
    const pageSegments = Array.from(new Set(formattedData.map(item => item.page_segment_name))).sort((a, b) => {
        if (a === 'その他') return 1 // aがその他の場合、後ろに移動
        if (b === 'その他') return -1 // bがその他の場合、前に移動
        return 0 // それ以外の順序は変更しない
    })

    // ページ分類毎のデータを取得
    const { barData, barChartSize } = getStackBarChartDataPerCategory(formattedData, pageSegments, 'page_segment_name', 'sessions', xAxisData)

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, '新規ユーザー率', 'new_user_rate')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'セッション',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: '新規ユーザー率',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const DailyPageSegmentLPConversionLineBarChart = ({ data }: { data: PageSegmentLandingPageLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.date)))

    // 上位5つ+その他でdataをフォーマット
    const formattedData = formatRanking(sortedData, 'page_segment_name', 'conversions', CHART_LIMIT, 'date')

    // ページ分類をユニークで取得
    const pageSegments = Array.from(new Set(formattedData.map(item => item.page_segment_name))).sort((a, b) => {
        if (a === 'その他') return 1 // aがその他の場合、後ろに移動
        if (b === 'その他') return -1 // bがその他の場合、前に移動
        return 0 // それ以外の順序は変更しない
    })

    // ページ分類毎のデータを取得
    const { barData, barChartSize } = getStackBarChartDataPerCategory(sortedData, pageSegments, 'page_segment_name', 'conversions', xAxisData)

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, 'CVR', 'conversion_rate')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'CV',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: 'CVR',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const DailyPageSegmentPagePathSessionLineBarChart = ({ data }: { data: PageSegmentPagePathLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.date)))

    // 上位+その他でdataをフォーマット
    const formattedData = formatRanking(sortedData, 'page_segment_name', 'sessions', CHART_LIMIT, 'date')
        
    // ページ分類をユニークで取得
    const pageSegments = Array.from(new Set(formattedData.map(item => item.page_segment_name))).sort((a, b) => {
        if (a === 'その他') return 1 // aがその他の場合、後ろに移動
        if (b === 'その他') return -1 // bがその他の場合、前に移動
        return 0 // それ以外の順序は変更しない
    })

    // ページ分類毎のデータを取得
    const { barData, barChartSize } = getStackBarChartDataPerCategory(formattedData, pageSegments, 'page_segment_name', 'sessions', xAxisData)

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, '新規ユーザー率', 'new_user_rate')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'セッション',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: '新規ユーザー率',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

export const DailyPageSegmentPagePathConversionLineBarChart = ({ data }: { data: PageSegmentPagePathLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // 日付をユニークにする
    const xAxisData = Array.from(new Set(sortedData.map(item => item.date)))

    // 上位5つ+その他でdataをフォーマット
    const formattedData = formatRanking(sortedData, 'page_segment_name', 'conversions', CHART_LIMIT, 'date')

    // ページ分類をユニークで取得
    const pageSegments = Array.from(new Set(formattedData.map(item => item.page_segment_name))).sort((a, b) => {
        if (a === 'その他') return 1 // aがその他の場合、後ろに移動
        if (b === 'その他') return -1 // bがその他の場合、前に移動
        return 0 // それ以外の順序は変更しない
    })

    // ページ分類毎のデータを取得
    const { barData, barChartSize } = getStackBarChartDataPerCategory(formattedData, pageSegments, 'page_segment_name', 'conversions', xAxisData)

    // 日付毎のCVRデータを取得
    const { lineData, lineChartSize } = getAverageRateLineChartData(sortedData, 'CVR', 'conversion_rate')

    // barDataに追加する
    barData.push(lineData)

    const yAxis = [
        {
            type: 'value',
            name: 'CV',
            min: barChartSize.min,
            max: barChartSize.max,
            interval: barChartSize.interval,
        },
        {
            type: 'value',
            name: 'CVR',
            min: lineChartSize.min,
            max: lineChartSize.max,
            interval: lineChartSize.interval,
            axisLabel: {
                formatter: '{value}%'
            },
            splitLine: {
                show: false, // 右側の軸にはグリッド線を非表示
            },
        },
    ]

    return (
        <EChartMixLineBar xAxisData={xAxisData} series={barData} yAxis={yAxis} />
    )
}

const getChartSize = (data: number[]) => {
    const minValue = 0
    const maxValue = Math.max(...data)
    const range = maxValue - minValue

    let interval
    let max

    if (range < 10) {
        interval = 1
        max = Math.ceil(maxValue / interval) * interval
    } else if (range < 100) {
        interval = 10
        max = Math.ceil(maxValue / interval) * interval
    } else if (range < 1000) {
        interval = 200
        max = Math.ceil(maxValue / interval) * interval
    } else {
        // 桁数を調べ, 桁数 - 2 の位で切り上げる
        const digits = Math.floor(Math.log10(maxValue)) + 1
        const factor = Math.pow(10, digits - 2)
        max = Math.ceil(maxValue / factor) * factor
        interval = Math.ceil(max / 5)
    }

    return {
        'interval': interval,
        'min': 0,
        'max': max,
    }
}


// StackBarChart用のデータへ整形する関数
export const getStackBarChartDataPerCategory = (data: any, categories: string[], targetColumn: string, valueColumn: string, dates: string[], keyDate: string = 'date') => {
    // チャネル毎に日次データをまとめる、対象日にデータがなければ０にし、全て値が0の場合には項目を削除する
    const barData = categories.map(category => {
        return {
            name: category,
            type: 'bar',
            stack: targetColumn,
            data: dates.map(date => {
                const dataForDate = data.find(item => item[keyDate] === date && item[targetColumn] === category)
                return dataForDate ? dataForDate[valueColumn] : 0
            })
        }
    }).filter(channel => channel.data.some(value => value !== 0))

    // 合計したデータを作成し、Maxやintervalの算出に使用する
    const totalData = dates.map(date => {
        return data.filter(item=> item[keyDate] === date).reduce((sum, item) => sum + item[valueColumn], 0)
    })

    const barChartSize = getChartSize(totalData)

    return { barData: barData, barChartSize: barChartSize }
}

// BarChart用のデータへ整形する関数
// 日付毎に値を合算
export const getSumBarChartDataByDate = (data: any, name: string, keyDate: string, valueColumn: string) => {
    // 日付毎のデータに整形
    const formattedData = formatDataByDate(data, keyDate, valueColumn)

    const date = formattedData.map(item => item[keyDate].toString())
    const aggregateData = formattedData.map(item => Number(item[valueColumn]))

    const barData = {
        name: name,
        type: 'bar',
        data: aggregateData,
    }
    const barChartSize = getChartSize(aggregateData)

    return {
        'date': date,
        'barData': barData,
        'barChartSize': barChartSize,
    }
}

// LineChart用のデータへ整形する関数
export const getAverageRateLineChartData = (data: any, name: string, column: string, keyDate: string = 'date') => {
    // 日付ごとに 対象カラムの平均を計算
    const aggregateData = data.reduce((acc: any, curr: any) => {
        const date = curr[keyDate]
        
        if (!acc[date]) {
            acc[date] = { totalRate: 0, count: 0 }
        }
        
        acc[date].totalRate += curr[column]
        acc[date].count += 1
    
        return acc;
    }, {});
    
    // 平均を計算し、折れ線グラフ用のデータ形式に変換
    const newUserRate = Object.keys(aggregateData).map(date => {
        const { totalRate, count } = aggregateData[date]
        const avgRate = totalRate / count;
        // console.log(`${date}:${avgRate}`)
        return avgRate
    })

    const lineData = {
        name: name,
        type: 'line',
        yAxisIndex: 1,
        data: newUserRate,
        stack: column,
        itemStyle: {
            color: '#EE6665'
        },
        tooltip: {
            valueFormatter: function (value: number) {
                return roundWithScale(value, 2).toFixed(2) + '%';
            }
        },
    }

    // min, max, intervalを算出
    const lineChartSize = getChartSize(newUserRate)

    return { lineData: lineData, lineChartSize: lineChartSize }
}

// APIから取得したデータを、指定したカラムの値順にソートし（降順）指定した数だけそのまま + それ以外を１つにまとめて返す関数
export const formatRanking = (data: any[], targetColumn: string, valueColumn: string, rank: number, keyDate: string) => {
    // 指定したカラム単位で指定した値の合計値を出す
    const totals = data.reduce((acc, current) => {
        const group = current[targetColumn]
        if (!acc[group]) {
            acc[group] = 0
        }
        acc[group] += current[valueColumn]
        return acc
    }, {})
    // データの多い順に並び替え
    const sortedTotals = Object.entries(totals).sort((a, b) => b[1] - a[1])

    // 指定した順位までのデータ（targetColumn）を配列化
    const allowedGroups = sortedTotals.slice(0, rank).map(([key, value]) => key)

    // 指定した順位はそのまま、それ以外は「その他」に変更
    const formattedData = processAndAggregateValue(data, allowedGroups, targetColumn, valueColumn, keyDate)

    return formattedData
}

// data: APIから取得したデータ
// allowedGroups: 指定した順位に入ったtargetColumnの配列
// targetColumn: session_default_channel_groupなど
// valueColumn: sessionsなど
// dateKey: date or year_month
const processAndAggregateValue = (data: any, allowedGroups: string[], targetColumn: string, valueColumn: string, dateKey: string) => {
    const result: any = []
    const othersMap: any = []

    data.forEach(item => {
        const group = item[targetColumn]

        // その他に分類
        const processedItem = {
            ...item,
            [targetColumn]: allowedGroups.includes(group) ? group : 'その他'
        }

        const date = processedItem[dateKey]
        const target = processedItem[targetColumn]
        const value = processedItem[valueColumn]

        if (target === 'その他') {
            if (!othersMap[date]) {
                othersMap[date] = {
                    [dateKey]: date,
                    [targetColumn]: target,
                    [valueColumn]: 0,
                }
                result.push(othersMap[date])
            }
            othersMap[date][valueColumn] += value 
        } else {
            result.push(processedItem)
        }
    })

    return result
}