import { FC, useContext, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../Page404"
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { MainContent } from "../../../Ui/MainContent"
import { ImageUpload, Input, Label, Radio } from "../../../Ui/Form"
import { SubmitButton } from "../../../Ui/Button"
import { User } from "../../../../Types/User"
import { getUser, updateUser } from "../../../../Hooks/User/User"
import { AuthContext, SetAuthContext } from "../../../../Providers/UserAuth"
import { AxiosResponse } from "axios"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const UserEdit: FC = () => {

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [imageURL, setImageURL] = useState('')

    const useFormMethods = useForm<User>()
    const { handleSubmit, setValue, setError } = useFormMethods

    // ラジオボタンの選択肢
    const radioItems = [
        { value: 1, label: '有効' },
        { value: 0, label: '無効' },
    ]

    // ログイン情報のContext
    const authUser = useContext(AuthContext)
    const setAuthUser = useContext(SetAuthContext)

    const onSubmit: SubmitHandler<User> = (data) => {
        setIsLoading(true)

        updateUser(data)
            .then((response: AxiosResponse) => {
                const { data: user } = response
                // 自分のデータの場合はContextを更新
                if (user.id === authUser?.id) {
                    setAuthUser(user)
                }

                navigate('/users', { state: 'ユーザーを更新しました' })
            })
            .catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.is_active) setError('is_active', { type: 'custom', message: errors.is_active.join('/') })
                if (errors.name) setError('name', { type: 'custom', message: errors.name.join('/') })
                if (errors.email) setError('email', { type: 'custom', message: errors.email.join('/') })
            })
    }

    
    useEffect(() => {
        const getData = async (id: string) => {
            // 担当者データ
            const data = await getUser(id)

            setValue('id', data.id)
            setValue('is_active', data.is_active)
            setValue('name', data.name)
            setValue('email', data.email)
            setImageURL(data.logo)

            setIsLoading(false)
        }

        getData(id)
    }, [])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<PeopleAltIcon />}>共有ユーザー</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input name="id" type="hidden" disabled={true} />

                        <Label isRequire={true}>担当者名</Label>
                        <Input name="name" type="text" />
                    
                        <Label isRequire={true}>メールアドレス</Label>
                        <Input name="email" type="email" />

                        <Label isRequire={true}>ログイン</Label>
                        <Radio name="is_active" items={radioItems} />

                        <Label isRequire={false}>アイコン画像</Label>
                        <ImageUpload
                            name="logo"
                            imagePath={imageURL}
                            savePath="/user/user_logo"
                            previewWidth={150}
                        />

                        <div className="mt-8">             
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}