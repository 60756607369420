import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { Page404 } from "../Components/Pages/User/Page404"
import { Layout } from "../Components/Layouts/User/Layout"
import { UserList } from "../Components/Pages/User/User/UserList"
import { UserCreate } from "../Components/Pages/User/User/UserCreate"
import { UserEdit } from "../Components/Pages/User/User/UserEdit"
import { authUser } from "../Hooks/User/Auth"
import { Dashboard } from "../Components/Pages/User/Dashboard"
import { SiteList } from "../Components/Pages/User/Site/SiteList"
import { SiteCreate } from "../Components/Pages/User/Site/SiteCreate"
import { SiteEdit } from "../Components/Pages/User/Site/SiteEdit"
import { GASetting } from "../Components/Pages/User/Site/GASetting"
import { SiteUserList } from "../Components/Pages/User/Site/SiteUser/SiteUserList"
import { SiteUserCreate } from "../Components/Pages/User/Site/SiteUser/SiteUserCreate"
import { MonthlyTargetList } from "../Components/Pages/User/Site/MonthlyTarget/MonthlyTargetList"
import { MonthlyTargetCreate } from "../Components/Pages/User/Site/MonthlyTarget/MonthlyTargetCreate"
import { ConversionEventSettingList } from "../Components/Pages/User/Site/ConversionEventSetting/ConversionEventSettingList"
import { ConversionEventSettingCreate } from "../Components/Pages/User/Site/ConversionEventSetting/ConversionEventSettingCreate"
import { ConversionPagePathSettingList } from "../Components/Pages/User/Site/ConversionPagePathSetting/ConversionPagePathSettingList"
import { ConversionPagePathSettingCreate } from "../Components/Pages/User/Site/ConversionPagePathSetting/ConversionPagePathSettingCreate"
import { DailyGALandingPageLog } from "../Components/Pages/User/Analytics/DailyGALandingPageLog"
import { DailyGAPagePathLog } from "../Components/Pages/User/Analytics/DailyGAPagePathLog"
import { DailyGAChannelGroupLog } from "../Components/Pages/User/Analytics/DailyGAChannelGroupLog"
import { MonthlyLandingPageLog } from "../Components/Pages/User/Analytics/MonthlyLandingPageLog"
import { MonthlyPagePathLog } from "../Components/Pages/User/Analytics/MonthlyPagePathLog"
import { MonthlyChannelGroupLog } from "../Components/Pages/User/Analytics/MonthlyChannelGroupLog"
import { DailySummaryLog } from "../Components/Pages/User/Analytics/DailySummaryLog"
import { MonthlySummaryLog } from "../Components/Pages/User/Analytics/MonthlySummaryLog"
import { DailyGADeviceCategoryLog } from "../Components/Pages/User/Analytics/DailyGADeviceCategoryLog"
import { MonthlyDeviceCategoryLog } from "../Components/Pages/User/Analytics/MonthlyDeviceCategoryLog"
import { PageSegmentCreate } from "../Components/Pages/User/PageSegment/PageSegmentCreate"
import { PageSegmentList } from "../Components/Pages/User/PageSegment/PageSegmentList"
import { PageSegmentEdit } from "../Components/Pages/User/PageSegment/PageSegmentEdit"
import { DailyPageSegmentPagePathLog } from "../Components/Pages/User/Analytics/DailyPageSegmentPagePathLog"
import { DailyPageSegmentLandingPageLog } from "../Components/Pages/User/Analytics/DailyPageSegmentLandingPageLog"
import { MonthlyPageSegmentLandingPageLog } from "../Components/Pages/User/Analytics/MonthlyPageSegmentLandingPageLog"
import { MonthlyPageSegmentPagePathLog } from "../Components/Pages/User/Analytics/MonthlyPageSegmentPagePathLog"
import { ConversionList } from "../Components/Pages/User/Site/Conversion/ConversionList"
import { ConversionCreate } from "../Components/Pages/User/Site/Conversion/ConversionCreate"
import { ConversionEdit } from "../Components/Pages/User/Site/Conversion/ConversionEdit"
import { AllLandingPageLog } from "../Components/Pages/User/Analytics/AllLandingPageLog"
import { AllChannelGroupLog } from "../Components/Pages/User/Analytics/AllChannelGroupLog"
import { AllDeviceCategoryLog } from "../Components/Pages/User/Analytics/AllDeviceCategoryLog"
import { AllPagePathLog } from "../Components/Pages/User/Analytics/AllPagePathLog"
import { AllPageSegmentPagePathLog } from "../Components/Pages/User/Analytics/AllPageSegmentPagePathLog"
import { AllPageSegmentLandingPageLog } from "../Components/Pages/User/Analytics/AllPageSegmentLandingPageLog"
import { CVDailyGAChannelGroupLog } from "../Components/Pages/User/Analytics/CVDailyGAChannelGroupLog"

/**
 * ログイン済みのみアクセス可能
 * @returns 
 */
const guardLoader = async () => {
    const user = await authUser()
    return user ? true : window.location.href='/login'
}

/**
 * ログイン済み && サイト設定済み のみアクセス可能
 * @returns 
 */
const guardSiteLoader = async () => {
    const user = await authUser()
    if (!user) window.location.href='/login'

    return true
    // const sites = await checkAuth()
    // return isGAAuth ? true : window.location.href='/sites'
}

/**
 * ログインしていない場合のみアクセス可能
 * @returns 
 */
const guestLoader = async () => {
    const user = await authUser()
    return user ? window.location.href='/xxxx' : true
}

export const userRouter = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            // analytics/dashboard
            {
                path: '/analytics/dashboard',
                element: <Dashboard />,
                loader: guardSiteLoader
            },

            // 日次
            {
                path: '/analytics/daily_landing_page_logs',
                element: <DailyGALandingPageLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/daily_page_path_logs',
                element: <DailyGAPagePathLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/daily_channel_group_logs',
                element: <DailyGAChannelGroupLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/daily_device_category_logs',
                element: <DailyGADeviceCategoryLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/daily_page_segment_landing_page_logs',
                element: <DailyPageSegmentLandingPageLog />,
                loader: guardLoader
            },
            {
                path: '/analytics/daily_page_segment_page_path_logs',
                element: <DailyPageSegmentPagePathLog />,
                loader: guardLoader
            },
            {
                path: '/analytics/daily_summary_logs',
                element: <DailySummaryLog />,
                loader: guardSiteLoader
            },
            // コンバージョン別
            {
                path: '/analytics/conversion_daily_channel_group_logs',
                element: <CVDailyGAChannelGroupLog />,
                loader: guardSiteLoader
            },


            // 月次
            {
                path: '/analytics/monthly_landing_page_logs',
                element: <MonthlyLandingPageLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/monthly_page_path_logs',
                element: <MonthlyPagePathLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/monthly_channel_group_logs',
                element: <MonthlyChannelGroupLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/monthly_device_category_logs',
                element: <MonthlyDeviceCategoryLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/monthly_summary_logs',
                element: <MonthlySummaryLog />,
                loader: guardSiteLoader
            },
            {
                path: '/analytics/monthly_page_segment_landing_page_logs',
                element: <MonthlyPageSegmentLandingPageLog />,
                loader: guardLoader
            },
            {
                path: '/analytics/monthly_page_segment_page_path_logs',
                element: <MonthlyPageSegmentPagePathLog />,
                loader: guardLoader
            },

            // 全期間
            {
                path: '/analytics/all_channel_group_logs',
                element: <AllChannelGroupLog />,
                loader: guardLoader
            },
            {
                path: '/analytics/all_device_category_logs',
                element: <AllDeviceCategoryLog />,
                loader: guardLoader
            },
            {
                path: '/analytics/all_landing_page_logs',
                element: <AllLandingPageLog />,
                loader: guardLoader
            },
            {
                path: '/analytics/all_page_path_logs',
                element: <AllPagePathLog />,
                loader: guardLoader
            },
            {
                path: '/analytics/all_page_segment_landing_page_logs',
                element: <AllPageSegmentLandingPageLog />,
                loader: guardLoader
            },
            {
                path: '/analytics/all_page_segment_page_path_logs',
                element: <AllPageSegmentPagePathLog />,
                loader: guardLoader
            },

            // page_segments
            {
                path: '/page_segments',
                element: <PageSegmentList />,
                loader: guardLoader
            },
            {
                path: '/page_segments/create',
                element: <PageSegmentCreate />,
                loader: guardLoader
            },
            {
                path: '/page_segments/edit',
                element: <PageSegmentEdit />,
                loader: guardLoader
            },
            // sites
            {
                path: '/sites',
                element: <SiteList />,
                loader: guardLoader
            },
            {
                path: '/sites/create',
                element: <SiteCreate />,
                loader: guardLoader
            },
            {
                path: '/sites/edit',
                element: <SiteEdit />,
                loader: guardLoader
            },
            {
                path: '/sites/ga_setting',
                element: <GASetting />,
                loader: guardLoader
            },
            // sites/conversions
            {
                path: '/sites/conversions',
                element: <ConversionList />,
                loader: guardLoader
            },
            {
                path: '/sites/conversions/create',
                element: <ConversionCreate />,
                loader: guardLoader
            },
            {
                path: '/sites/conversions/edit',
                element: <ConversionEdit />,
                loader: guardLoader
            },
            // sites/conversion_event_settings
            {
                path: '/sites/conversion_event_settings',
                element: <ConversionEventSettingList />,
                loader: guardLoader
            },
            {
                path: '/sites/conversion_event_settings/create',
                element: <ConversionEventSettingCreate />,
                loader: guardLoader
            },
            // sites/conversion_page_path_settings
            {
                path: '/sites/conversion_page_path_settings',
                element: <ConversionPagePathSettingList />,
                loader: guardLoader
            },
            {
                path: '/sites/conversion_page_path_settings/create',
                element: <ConversionPagePathSettingCreate />,
                loader: guardLoader
            },
            // sites/monthly_targets
            {
                path: '/sites/monthly_targets',
                element: <MonthlyTargetList />,
                loader: guardLoader
            },
            {
                path: '/sites/monthly_targets/create',
                element: <MonthlyTargetCreate />,
                loader: guardLoader
            },
            // sites/site_users
            {
                path: '/sites/site_users',
                element: <SiteUserList />,
                loader: guardLoader
            },
            {
                path: '/sites/site_users/create',
                element: <SiteUserCreate />,
                loader: guardLoader
            },
            // users
            {
                path: '/users',
                element: <UserList />,
                loader: guardLoader
            },
            {
                path: '/users/create',
                element: <UserCreate />,
                loader: guardLoader
            },
            {
                path: '/users/edit',
                element: <UserEdit />,
                loader: guardLoader
            },
            {
                path: '*',
                element: <Page404 />,
                loader: guardLoader,
            },
        ]
    }
])

export const UserRouteProvider = () => {
    return (
        <RouterProvider router={userRouter} />
    )
}