import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyDeviceCategoryLog, SearchMonthlyDeviceCategoryLog } from '../../Types/MonthlyDeviceCategoryLog'


export const getMonthlyDeviceCategoryLogList = async (searchParams: SearchMonthlyDeviceCategoryLog | undefined) => {
    const { data } = await axios.get('/api/monthly_device_category_logs', { params: searchParams })
    return data
}

export const useMonthlyDeviceCategoryLogList = (searchParams: SearchMonthlyDeviceCategoryLog | undefined): UseQueryResult<MonthlyDeviceCategoryLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyDeviceCategoryLogList', searchParams],
        queryFn: () => getMonthlyDeviceCategoryLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getThisMonthMonthlyDeviceCategoryLogList = async (searchParams: SearchMonthlyDeviceCategoryLog | undefined) => {
    const { data } = await axios.get('/api/this_month_device_category_logs', { params: searchParams })
    return data
}

export const useThisMonthMonthlyDeviceCategoryLogList = (searchParams: SearchMonthlyDeviceCategoryLog | undefined): UseQueryResult<MonthlyDeviceCategoryLog[]> => {
    return useQuery({
        queryKey: ['getThisMonthMonthlyDeviceCategoryLogList', searchParams],
        queryFn: () => getThisMonthMonthlyDeviceCategoryLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}