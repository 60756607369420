import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { SiteUser } from '../../Types/SiteUser'

export const getSiteUserList = async (site_id: string) => {
    const { data } = await axios.get(`/api/site_users/${site_id}`)
    return data
}


export const updateSiteUser = async (data: SiteUser): Promise<AxiosResponse> => {
    return await axios.patch('/api/site_users', data)
}

export const createSiteUser = async (data: SiteUser): Promise<AxiosResponse> => {
    return await axios.post('/api/site_users', data)
}

// use react-query
export const useSiteUserList = (site_id: string): UseQueryResult<SiteUser[]> => {
    return useQuery({
        queryKey: ['siteUserList', site_id],
        queryFn: () => getSiteUserList(site_id),
    })
}