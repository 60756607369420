import { FC } from "react"
import { Avatar, Button } from "@mui/material"
import { format } from 'date-fns' 
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { ColumnHelper, createColumnHelper } from '@tanstack/react-table'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { User } from "../../../../Types/User"
import { Loading } from "../../../Ui/Loading"
import { Message } from "../../../Ui/Message"
import { H1 } from "../../../Ui/H1"
import { PaginationTable } from "../../../Ui/Table"
import { CreateButton, CSVDownloadButton } from "../../../Ui/Button"
import { useUserList } from "../../../../Hooks/User/User"


export const UserList: FC = () => {
    const { state: message } = useLocation()

    // データ取得
    const { isPending, data } = useUserList()

    const columnHelper = createColumnHelper<User>()
    const columns = setColumns(columnHelper)

    if (isPending) return <Loading isLoading={isPending} />
    return (
        <>        
            <Message message={message} />
            <H1 icon={<PeopleAltIcon />}>共有ユーザー</H1>
            
            <PaginationTable data={data} columns={columns}>
                <CreateButton path="/users/create" className="ml-2" />
                <CSVDownloadButton columns={csvColumns} data={data} filename="共有ユーザー" className="ml-2" />
            </PaginationTable>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<User>) => {
    return [
        columnHelper.accessor('name', {
            header: '氏名',
            cell: (props) => (
                <>
                    <div className="flex items-center">                    
                        <Avatar 
                            alt={props.row.original.name} 
                            sx={{ width: 36, height: 36, display: "inline-flex", marginRight: 1 }} 
                            src={props.row.original.logo}
                        />
                        {props.row.original.name}
                    </div>
                </>
            )
        }),
        columnHelper.accessor('email', {
            header: 'メールアドレス'
        }),
        columnHelper.accessor('is_active', {
            header: 'ログイン',
            cell: (props) => (
                props.row.original.is_active ? <><span className="mr-1 text-green-600 font-bold text-lg">●</span>有効</> : <><span className="mr-1 text-red-700 font-bold text-lg">●</span>無効</>
            )
        }),
        columnHelper.accessor('updated_at', {
            header: '最終更新日',
            cell: (props) => (
                format(props.row.original.updated_at, 'yyyy/MM/dd HH:mm')
            )
        }),
        columnHelper.display({
            id: 'action',
            header: '',
            cell: (props) => (
                <>
                    <Link to={`/users/edit?id=${props.row.original.id}`}><Button>編集</Button></Link>
                </>
            )
        })
    ]
}

const csvColumns = [
    { header: '氏名', key: 'name' },
    { header: 'メールアドレス', key: 'email' },
    { header: 'ログイン', key: 'is_active', fn: (value: string) => Boolean(value) ? '有効' : '無効' },
    { header: '最終更新日', key: 'updated_at', fn: (value: string) => format(value, 'yyyy/MM/dd HH:mm') },
]
