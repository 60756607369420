import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CreateMonthlyTarget, MonthlyTarget, SearchMonthlyTarget, SearchThisMonthTarget } from '../../Types/MonthlyTarget'

export const getMonthlyTargetList = async (site_id: string, searchParams?: SearchMonthlyTarget | undefined) => {
    const { data } = await axios.get(`/api/monthly_targets/${site_id}`, { params: searchParams })
    return data
}

// export const updateSiteUser = async (data: SiteUser): Promise<AxiosResponse> => {
//     return await axios.patch('/api/site_users', data)
// }

export const createMonthlyTarget = async (data: CreateMonthlyTarget): Promise<AxiosResponse> => {
    return await axios.post('/api/monthly_targets', data)
}

// use react-query
export const useMonthlyTargetList = (site_id: string, searchParams?: SearchMonthlyTarget | undefined): UseQueryResult<MonthlyTarget[]> => {
    return useQuery({
        queryKey: ['monthlyTargetList', site_id, searchParams],
        queryFn: () => getMonthlyTargetList(site_id, searchParams),
    })
}

export const getThisMonthTarget = async (searchParams: SearchThisMonthTarget | undefined) => {
    const { data } = await axios.get('/api/this_month_target', { params: searchParams })
    return data
}

// use react-query
export const useThisMonthTarget = (searchParams: SearchThisMonthTarget | undefined): UseQueryResult<MonthlyTarget> => {
    return useQuery({
        queryKey: ['getThisMonthTarget', searchParams],
        queryFn: () => getThisMonthTarget(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
