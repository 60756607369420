import { EChartHorizontalBar } from "../Ui/EChart"

interface AllHorizontalBarChartProps {
    data: any
    valueTitle: string
    valueColumn: string
    labelColumn: string
    limit?: number
}
export const AllLogHorizontalBarChart = ({ data, valueTitle, valueColumn, labelColumn, limit = 0 }: AllHorizontalBarChartProps) => {
    const tmpData = limit > 0
        ? data.sort((a, b) => b[valueColumn] - a[valueColumn]).slice(0, limit)
        : data.sort((a, b) => b[valueColumn] - a[valueColumn])

    const formattedData = tmpData.sort((a, b) => a[valueColumn] - b[valueColumn])

    const labels = formattedData.map((item) => item[labelColumn])
    const values = formattedData.map((item) => item[valueColumn])

    return <EChartHorizontalBar labels={labels} values={values} valueTitle={valueTitle} />
}