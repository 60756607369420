import { FC, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { User } from "../../../../Types/User"
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { MainContent } from "../../../Ui/MainContent"
import { ImageUpload, Input, Label, Radio } from "../../../Ui/Form"
import { SubmitButton } from "../../../Ui/Button"
import { CompanySelect } from "../../../Molecules/CompanySelect"
import { createUser } from "../../../../Hooks/User/User"

export const UserCreate: FC = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [imageURL, setImageURL] = useState('')

    const useFormMethods = useForm<User>()
    const { handleSubmit, setError } = useFormMethods

    // ラジオボタンの選択肢
    const radioItems = [
        { value: 1, label: '有効' },
        { value: 0, label: '無効' },
    ]

    const onSubmit: SubmitHandler<User> = (data) => {
        setIsLoading(true)
        
        createUser(data)
            .then(() => {
                navigate('/users', { state: 'ユーザーを登録しました' })
            }).catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.company_id) setError('company_id', { type: 'custom', message: errors.company_id.join('/') })
                if (errors.is_active) setError('is_active', { type: 'custom', message: errors.is_active.join('/') })
                if (errors.name) setError('name', { type: 'custom', message: errors.name.join('/') })
                if (errors.email) setError('email', { type: 'custom', message: errors.email.join('/') })
                if (errors.password) setError('password', { type: 'custom', message: errors.password.join('/') })
            })
    }

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<PeopleAltIcon />}>共有ユーザー</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>                        
                        <Label isRequire={true}>ユーザー名</Label>
                        <Input name="name" type="text" />
                    
                        <Label isRequire={true}>メールアドレス</Label>
                        <Input name="email" type="email" />

                        <Label isRequire={true}>ログイン</Label>           
                        <Radio name="is_active" items={radioItems} />

                        <Label isRequire={false}>アイコン画像</Label>
                        <ImageUpload
                            name="logo"
                            imagePath={imageURL}
                            savePath="/user/user_logo"
                            previewWidth={150}
                        />

                        <Label isRequire={true}>パスワード</Label>
                        <Input name="password" type="password" />

                        <Label isRequire={true}>パスワード（確認）</Label>
                        <Input name="password_confirmation" type="password" />

                        <div className="mt-8">
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}