import React from 'react'
import ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ReactQueryProvider } from './Providers/ReactQuery'
import { ThemeProvider } from './Providers/Theme'
import { AuthProvider } from './Providers/UserAuth'
import { UserRouteProvider } from './Providers/UserRoute'
import { SiteProvider } from './Providers/Site'

const Index = () => {
    return (
        <React.StrictMode>
            <ReactQueryProvider>
                <AuthProvider>
                    <SiteProvider>
                        <ThemeProvider>
                            <CssBaseline />
                            <UserRouteProvider />
                        </ThemeProvider>
                    </SiteProvider>
                </AuthProvider>
            </ReactQueryProvider>
        </React.StrictMode>
    )
}

const app = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);
app.render(<Index />);