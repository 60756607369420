import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AnnualMonthlySummaryLog, MonthlySummaryLog, SearchAnnualMonthlySummaryLog, SearchMonthlySummaryLog } from '../../Types/MonthlySummaryLog'


export const getMonthlySummaryLogList = async (searchParams: SearchMonthlySummaryLog | undefined) => {
    const { data } = await axios.get('/api/monthly_summary_logs', { params: searchParams })
    return data
}

export const useMonthlySummaryLogList = (searchParams: SearchMonthlySummaryLog | undefined): UseQueryResult<MonthlySummaryLog[]> => {
    return useQuery({
        queryKey: ['getMonthlySummaryLogList', searchParams],
        queryFn: () => getMonthlySummaryLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getAnnualMonthlySummaryLogList = async (searchParams: SearchAnnualMonthlySummaryLog | undefined) => {
    const { data } = await axios.get('/api/annual_monthly_summary_logs', { params: searchParams })
    return data
}

export const useAnnualMonthlySummaryLogList = (searchParams: SearchAnnualMonthlySummaryLog | undefined): UseQueryResult<AnnualMonthlySummaryLog> => {
    return useQuery({
        queryKey: ['getAnnualMonthlySummaryLogList', searchParams],
        queryFn: () => getAnnualMonthlySummaryLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}