import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllLandingPageLog, SearchAllLandingPageLog } from '../../Types/AllLandingPageLog'


export const getAllLandingPageLogList = async (searchParams: SearchAllLandingPageLog | undefined) => {
    const { data } = await axios.get('/api/grouped_ga_landing_page_logs', { params: searchParams })
    return data
}

export const useAllLandingPageLogList = (searchParams: SearchAllLandingPageLog | undefined): UseQueryResult<AllLandingPageLog[]> => {
    return useQuery({
        queryKey: ['getAllLandingPageLogList', searchParams],
        queryFn: () => getAllLandingPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
