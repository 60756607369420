import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { User } from '../../Types/User'
import { useMemo } from 'react'

export const getUserList = async () => {
    const { data } = await axios.get('/api/users')
    return data
}

export const getUser = async (id: string) => {
    const { data } = await axios.get(`/api/users/${id}`)
    return data
}

export const updateUser = async (data: User): Promise<AxiosResponse> => {
    return await axios.patch('/api/users', data)
}

export const createUser = async (data: User): Promise<AxiosResponse> => {
    return await axios.post('/api/users', data)
}

// use react-query
export const useUserList = (): UseQueryResult<User[]> => {
    return useQuery({
        queryKey: ['clientList'],
        queryFn: getUserList,
    })
}

export const useUserSelectOptions = () => {
    const { data: userList, ...queryInfo } = useUserList()

    const selectOptions = useMemo(() => {
        if (!userList) return []

        return userList.map((user: User) => (
            {
                value: user.id,
                label: `${user.name}（${user.email}）`,
            }
        ))
    }, [userList])

    return { selectOptions, ...queryInfo }
}