
import { Select } from "../Ui/Form"

export const SearchTypeSelect = ({name}: {name: string}) => {
    const selectOptions = [
        { value: 1, label: '次と完全一致'},
        { value: 2, label: '含む'},
        { value: 3, label: '先頭が一致'},
        { value: 4, label: '最後が一致'},
        { value: 5, label: '含まない'},
    ]

    return (
        <div style={{ width: 140, marginLeft: '2px' }}>
            <Select name={name} options={selectOptions} />
        </div>
    )
}