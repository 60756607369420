
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { Button, Card, CardContent, Chip, IconButton } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import { ConversionRule } from "../../Types/Conversion"
import { GAEventSelect } from "./GAEventSelect"
import { Input, Select } from "../Ui/Form"

interface ConversionRulePartsProps {
    conversionRules: ConversionRule[]
    setConversionRules: React.Dispatch<React.SetStateAction<ConversionRule[]>>
    siteId: string
}
export const ConversionRuleParts: FC<ConversionRulePartsProps> = ({ conversionRules, setConversionRules, siteId }) => {
    const { setValue, getValues } = useFormContext()
    const [rules, setRules] = useState<ConversionRule[]>(conversionRules)

    useEffect(() => {
        setRules(conversionRules)
        setValue('rules', conversionRules)
    }, [conversionRules, setValue])

    const handleTypeChange = (index: number, newType: string) => {
        const updatedRules = rules.map((rule, i) => (
            i === index ? { ...rule, type: newType } : rule
        ))

        setRules(updatedRules)
        setValue(`rules`, updatedRules)
        setConversionRules(updatedRules) // 親コンポーネントに反映
    }

    const handleDataChange = (index: number, newData: string) => {
        const updatedRules = rules.map((rule, i) => (
            i === index ? { ...rule, data: newData } : rule
        ))

        setRules(updatedRules)
        setValue(`rules`, updatedRules)
        setConversionRules(updatedRules) // 親コンポーネントに反映
    }

    const handleAddRule = () => {
        const currentRules = getValues(`rules`) || []
        const newRule = { type: "event", data: "" }
        const updatedRules = [...currentRules, newRule]

        setRules(updatedRules)
        setValue(`rules`, updatedRules)
        setConversionRules(updatedRules) // 親コンポーネントに反映
    }

    const handleRemoveRule = (index: number) => {
        const currentRules = getValues('rules') || []
        const updatedRules = currentRules.filter((_: ConversionRule, i: number) => i !== index)

        setRules(updatedRules)
        setValue('rules', updatedRules)
        setConversionRules(updatedRules) // 親コンポーネントに反映
    }

    if (rules.length === 0) return <div>Loading...</div>
    return (
        <Card>
            <CardContent>
                {rules.map((rule, ruleIndex) => (
                    <Fragment key={`rule-fragment-${ruleIndex}`}>
                        {ruleIndex !== 0 && (
                            <Chip key={`chip-${ruleIndex}`} label="または" size="small" sx={{ marginTop: "5px", marginBottom: "5px", marginLeft: "250px"}} />
                        )}
                        <Rule 
                            key={`rule-${ruleIndex}`} 
                            ruleIndex={ruleIndex} 
                            rule={rule} 
                            siteId={siteId} 
                            handleRemoveRule={handleRemoveRule} 
                            handleTypeChange={handleTypeChange}
                            handleDataChange={handleDataChange}
                        />
                    </Fragment>
                ))}
                <Button type="button" onClick={handleAddRule}>
                    条件を追加
                </Button>
            </CardContent>
        </Card>
    )
}

interface RuleProps {
    ruleIndex: number
    rule: ConversionRule
    siteId: string
    handleRemoveRule: (index: number) => void
    handleTypeChange: (index: number, newData: string) => void
    handleDataChange: (index: number, newData: string) => void
}
export const Rule: FC<RuleProps> = ({ rule, ruleIndex, siteId, handleRemoveRule, handleTypeChange, handleDataChange }) => {
    const [selectedType, setSelectedType] = useState(rule.type);

    const typeOptions = [
        { label: "イベント", value: "event"},
        { label: "URL", value: "url"},
    ]

    const handleTypeSelect = (newValue: any) => {
        handleTypeChange(ruleIndex, newValue)
        setSelectedType(newValue)
    }

    useEffect(() => {
        setSelectedType(rule.type)
    }, [rule])

    return (
        <>  
            <div key={`rule-${ruleIndex}`} className="flex items-start">
                <div className="inline-block w-32 mr-2">
                    <Select 
                        name={`rules.${ruleIndex}.type`} 
                        options={typeOptions} 
                        onChange={handleTypeSelect} 
                    />
                </div>

                <div className="inline-block w-96">
                    { selectedType === 'event' ? (
                        <GAEventSelect key={`rule-ga-event-select-${ruleIndex}`} siteId={siteId} name={`rules.${ruleIndex}.data`} onChange={(value) => handleDataChange(ruleIndex, String(value))} />
                    ) : (
                        <Input key={`rule-url-${ruleIndex}`} type="text" name={`rules.${ruleIndex}.data`} onChange={(value) => handleDataChange(ruleIndex, String(value)) }/>
                    )}
                </div>
                
                {ruleIndex !== 0 && (
                    <IconButton onClick={() => handleRemoveRule(ruleIndex)}><DeleteIcon /></IconButton>
                )}
            </div>
        </>
    )    
}
