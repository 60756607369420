import { FC, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../../Ui/Loading"
import { H1 } from "../../../../Ui/H1"
import { AddButton } from "../../../../Ui/Button"
import { getSite } from "../../../../../Hooks/User/Site"
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table"
import { PaginationTable } from "../../../../Ui/Table"
import { Site } from "../../../../../Types/Site"
import { Message } from "../../../../Ui/Message"
import { useConversionEventSettingList } from "../../../../../Hooks/User/ConversionEventSetting"
import { ConversionPagePathSetting } from "../../../../../Types/ConversionPagePathSetting"
import { useConversionPagePathSettingList } from "../../../../../Hooks/User/ConversionPagePathSetting"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const ConversionPagePathSettingList: FC = () => {
    const { state: message } = useLocation()

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [site, setSite] = useState<Site>()


    useEffect(() => {
        const getData = async (id: string) => {
            const site = await getSite(id)
            setSite(site)
        }

        getData(id)
    }, [])


    // データ取得
    const { isPending, data } = useConversionPagePathSettingList(id)

    const columnHelper = createColumnHelper<ConversionPagePathSetting>()
    const columns = setColumns(columnHelper)

    if (isPending) return <Loading isLoading={isPending} />
    return (
        <>
            <Message message={message} />
            <H1 className="mb-12" icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}フォーム設定）</H1>
            <PaginationTable data={data} columns={columns} isSearchParts={false}>
                <AddButton path={`/sites/conversion_page_path_settings/create?id=${id}`} className="ml-2" />
            </PaginationTable>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<ConversionPagePathSetting>) => {
    return [
        columnHelper.accessor('page_type.name', {
            header: 'タイプ',
        }),
        columnHelper.accessor('page_path', {
            header: 'ページパス',
        }),
    ]
}