import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllPagePathLog, SearchAllPagePathLog } from '../../Types/AllPagePathLog'


export const getAllPagePathLogList = async (searchParams: SearchAllPagePathLog | undefined) => {
    const { data } = await axios.get('/api/grouped_ga_page_path_logs', { params: searchParams })
    return data
}

export const useAllPagePathLogList = (searchParams: SearchAllPagePathLog | undefined): UseQueryResult<AllPagePathLog[]> => {
    return useQuery({
        queryKey: ['getAllPagePathLogList', searchParams],
        queryFn: () => getAllPagePathLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
