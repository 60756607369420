import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllPageSegmentPagePathLog, SearchAllPageSegmentPagePathLog } from '../../Types/AllPageSegmentPagePathLog'


export const getAllPageSegmentPagePathLogList = async (searchParams: SearchAllPageSegmentPagePathLog | undefined) => {
    const { data } = await axios.get('/api/grouped_page_segment_page_path_logs', { params: searchParams })
    return data
}

export const useAllPageSegmentPagePathLogList = (searchParams: SearchAllPageSegmentPagePathLog | undefined): UseQueryResult<AllPageSegmentPagePathLog[]> => {
    return useQuery({
        queryKey: ['getAllPageSegmentPagePathLogList', searchParams],
        queryFn: () => getAllPageSegmentPagePathLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
