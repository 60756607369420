import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CVDailyGAChannelGroupLog, GAChannelGroupLog, SearchGAChannelGroupLog } from '../../Types/GAChannelGroupLog'


export const getGAChannelGroupLogList = async (searchParams: SearchGAChannelGroupLog | undefined) => {
    const { data } = await axios.get('/api/ga_channel_group_logs', { params: searchParams })
    return data
}

export const useGAChannelGroupLogList = (searchParams: SearchGAChannelGroupLog | undefined): UseQueryResult<GAChannelGroupLog[]> => {
    return useQuery({
        queryKey: ['getGAChannelGroupLogList', searchParams],
        queryFn: () => getGAChannelGroupLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getCVGAChannelGroupLogList = async (searchParams: SearchGAChannelGroupLog | undefined) => {
    const { data } = await axios.get('/api/conversion_ga_channel_group_logs', { params: searchParams })
    return data
}

export const useCVGAChannelGroupLogList = (searchParams: SearchGAChannelGroupLog | undefined): UseQueryResult<CVDailyGAChannelGroupLog> => {
    return useQuery({
        queryKey: ['getCVGAChannelGroupLogList', searchParams],
        queryFn: () => getCVGAChannelGroupLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}