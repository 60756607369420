import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Event, Property } from '../../Types/GoogleAnalytics'
import { useMemo } from 'react'

/**
 * プロパティ関連
 */
export const getPropertyList = async (site_id: string) => {
    const { data } = await axios.get(`/api/ga/properties/${site_id}`)
    return data
}

// use react-query
export const usePropertyList = (site_id: string): UseQueryResult<Property[]> => {
    return useQuery({
        queryKey: ['propertyList', site_id],
        queryFn: () => getPropertyList(site_id),
    })
}

export const usePropertySelectOptions = (site_id: string) => {
    const { data: propertyList, ...queryInfo } = usePropertyList(site_id)

    const options = useMemo(() => {
        if (!propertyList) return []

        return propertyList.map((property: Property) => (
            {
                label: `${property.name} (${property.id})`,
                value: property.id,
            }
        ))
    }, [propertyList])

    return { options, ...queryInfo }
}

/**
 * イベント関連
 */
export const getEventList = async (site_id: string) => {
    const { data } = await axios.get(`/api/ga/events/${site_id}`)
    return data
}

// use react-query
export const useEventList = (site_id: string): UseQueryResult<Event[]> => {
    return useQuery({
        queryKey: ['eventList', site_id],
        queryFn: () => getEventList(site_id),
    })
}

export const useEventSelectOptions = (site_id: string) => {
    const { data: eventList, ...queryInfo } = useEventList(site_id)

    const options = useMemo(() => {
        if (!eventList) return []

        return eventList.map((event: Event) => (
            {
                label: event.name,
                value: event.name,
            }
        ))
    }, [eventList])

    return { options, ...queryInfo }
}