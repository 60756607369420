import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { SearchSummaryLog, SummaryLog } from '../../Types/SummaryLog'


export const getSummaryLogList = async (searchParams: SearchSummaryLog | undefined) => {
    const { data } = await axios.get('/api/summary_logs', { params: searchParams })
    return data
}

export const useSummaryLogList = (searchParams: SearchSummaryLog | undefined): UseQueryResult<SummaryLog[]> => {
    return useQuery({
        queryKey: ['getSummaryLogList', searchParams],
        queryFn: () => getSummaryLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
