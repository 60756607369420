import { ReactNode, createContext, useContext, useState } from "react";

interface SiteContextProps {
    siteId: string | number | undefined
    setSiteId: (value: string | number) => void
}

// Contextを宣言（初期値はundefined）
const SiteContext = createContext<SiteContextProps | undefined>(undefined)

export const useSite = (): SiteContextProps => {
    const context = useContext(SiteContext)
    
    if (!context) {
        throw new Error('useSite must be used within a SiteProvider')
    }

    return context
}

export const SiteProvider = ({ children }: { children?: ReactNode}) => {
    const [siteId, setSiteId] = useState<string | number>()

    return (
        <SiteContext.Provider value={{ siteId, setSiteId }}>
            {children}
        </SiteContext.Provider>
    )
}