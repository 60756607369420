import { FC, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../../Ui/Loading"
import { H1 } from "../../../../Ui/H1"
import { MainContent } from "../../../../Ui/MainContent"
import { Input, Label } from "../../../../Ui/Form"
import { AddButton, CreateButton, SubmitButton } from "../../../../Ui/Button"
import { GAPropertySelect } from "../../../../Molecules/GAPropertySelect"
import { getGAPropertyBySiteId, updateGAProperty } from "../../../../../Hooks/User/GAProperty"
import { GAProperty } from "../../../../../Types/GAProperty"
import { getSite } from "../../../../../Hooks/User/Site"
import { getSiteUserList, useSiteUserList } from "../../../../../Hooks/User/SiteUser"
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table"
import { SiteUser } from "../../../../../Types/SiteUser"
import { PaginationTable } from "../../../../Ui/Table"
import { Avatar } from "@mui/material"
import { Site } from "../../../../../Types/Site"
import { Message } from "../../../../Ui/Message"
import { MonthlyTarget } from "../../../../../Types/MonthlyTarget"
import { useMonthlyTargetList } from "../../../../../Hooks/User/MonthlyTarget"
import { formatDateString } from "../../../../../Utils/Date"
import { useConversionEventSettingList } from "../../../../../Hooks/User/ConversionEventSetting"
import { ConversionEventSetting } from "../../../../../Types/ConversionEventSetting"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const ConversionEventSettingList: FC = () => {
    const { state: message } = useLocation()

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [site, setSite] = useState<Site>()


    useEffect(() => {
        const getData = async (id: string) => {
            const site = await getSite(id)
            setSite(site)
        }

        getData(id)
    }, [])


    // データ取得
    const { isPending, data } = useConversionEventSettingList(id)

    const columnHelper = createColumnHelper<ConversionEventSetting>()
    const columns = setColumns(columnHelper)

    if (isPending) return <Loading isLoading={isPending} />
    return (
        <>
            <Message message={message} />
            <H1 className="mb-12" icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}コンバージョン イベント設定）</H1>
            <PaginationTable data={data} columns={columns} isSearchParts={false}>
                <AddButton path={`/sites/conversion_event_settings/create?id=${id}`} className="ml-2" />
            </PaginationTable>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<ConversionEventSetting>) => {
    return [
        columnHelper.accessor('event', {
            header: 'イベント',
        }),
    ]
}