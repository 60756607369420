import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyLandingPageLog, SearchMonthlyLandingPageLog } from '../../Types/MonthlyLandingPageLog'


export const getMonthlyLandingPageLogList = async (searchParams: SearchMonthlyLandingPageLog | undefined) => {
    const { data } = await axios.get('/api/monthly_lainding_page_logs', { params: searchParams })
    return data
}

export const useMonthlyLandingPageLogList = (searchParams: SearchMonthlyLandingPageLog | undefined): UseQueryResult<MonthlyLandingPageLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyLandingPageLogList', searchParams],
        queryFn: () => getMonthlyLandingPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
