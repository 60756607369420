import ReactECharts from 'echarts-for-react'
import { useTheme } from '@mui/material'

export const EChartPie = ({ name, data }: { name: string, data: { value: number, name: string }[] }) => {
    // dataのvalueが全て0の場合には空文字を返す
    const total = data.reduce((sum, item) => sum + item.value, 0)
    if (total == 0) return ''

    const theme = useTheme()
    const chartColors = theme.chart.colors

    const option = {
        color: chartColors,
        tooltip: {
            trigger: 'item',
            position: (point: [number, number], params: any, dom: HTMLElement, rect: { width: number, height: number }) => {
                // `point`はマウスの位置
                // `params`にはアイテムの情報
                // `dom`はtooltipのDOM要素
                // `rect`はtooltipのサイズ
        
                // マウスの位置を基にして、少しオフセットする例
                return [point[0] - 70, point[1] + 30]
            }
        },
        legend: {
            top: '90%',
            left: 'center',
        },
        series: [
            {
                name: name,
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: false,
                    }
                },
                labelLine: {
                    show: false
                },
                data: data,
            }
        ]
    }

    return <ReactECharts option={option} style={{ height: '100%', width: '100%' }} />
}

interface EChartMixLineBarProps {
    xAxisData: string[]
    series: {
        name: string
        type: string
        tooltip?: object
        data: number[]
        stack?: string
        yAxisIndex?: number
        markArea?: object
    }[]
    yAxis: {
        type: string
        name: string
        min: number
        max: number
        interval: number
        axisLabel?: object
        splitLine?: object
    }[]
    onChartClick?: (params: any) => void
}
export const EChartMixLineBar = ({ xAxisData, series, yAxis, onChartClick }: EChartMixLineBarProps) => {
    const option = {
        tooltip: {
            trigger: 'axis',
            confine: false,  // カード内にツールチップを閉じ込めないようにする
            appendToBody: true,  // bodyにツールチップを追加する
            axisPointer: {
                type: 'cross',
                crossStyle: { color: '#999' }
            }
        },
        toolbox: {
            feature: {
                // dataView: { show: true, readOnly: false },
                // magicType: { show: true, type: ['line', 'bar'] },
                // restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        legend: {
            data: series.map(item => item.name),
            type: 'scroll',  // スクロールできるようにする
            orient: 'horizontal',  // 水平方向に表示
            top: 'bottom',  // グラフの下に配置
            itemWidth: 14,  // レジェンドアイテムの幅
            itemHeight: 10,  // レジェンドアイテムの高さ
            itemGap: 10,  // 各アイテム間の余白
            pageButtonItemGap: 10,  // スクロールボタンの間隔
            width: '90%',  // レジェンドの幅
        },
        grid: {
            left: '10%', // 余白の調整
            right: '10%',
            bottom: '20%',
            top: '15%',
        },
        xAxis: [
            {
                type: 'category',
                data: xAxisData,
                axisPointer: { type: 'shadow' },
            }
        ],
        yAxis: yAxis,
        series: series,
    }

    const events = onChartClick ? { click: onChartClick } : undefined

    return <ReactECharts option={option} style={{ height: '100%', width: '100%' }} onEvents={events} />
}

interface EChartHorizontalBarProps {
    valueTitle: string
    values: number[]
    labels: string[]
}
export const EChartHorizontalBar = ({ valueTitle, values, labels }: EChartHorizontalBarProps) => {
    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        // legend: {},
        grid: {
            left: '3%',
            right: '4%',
            top: '5%',
            bottom: '2%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
            data: labels,
            axisLabel: {
                show: false
            },
            axisTick: {
                show: false // y軸の目盛りを非表示
            },
        },
        series: [
            {
                name: valueTitle,
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    position: 'insideLeft',
                    formatter: '{b}',
                    color: '#bbb',
                    fontSize: 10,
                },
                emphasis: {
                    focus: 'series'
                },
                data: values,
            },
        ]
    }

    return <ReactECharts option={option} style={{ height: '100%', width: '100%' }} />
}