import { FC, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../../Ui/Loading"
import { H1 } from "../../../../Ui/H1"
import { MainContent } from "../../../../Ui/MainContent"
import { Input, Label } from "../../../../Ui/Form"
import { AddButton, CreateButton, SubmitButton } from "../../../../Ui/Button"
import { GAPropertySelect } from "../../../../Molecules/GAPropertySelect"
import { getGAPropertyBySiteId, updateGAProperty } from "../../../../../Hooks/User/GAProperty"
import { GAProperty } from "../../../../../Types/GAProperty"
import { getSite } from "../../../../../Hooks/User/Site"
import { getSiteUserList, useSiteUserList } from "../../../../../Hooks/User/SiteUser"
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table"
import { SiteUser } from "../../../../../Types/SiteUser"
import { PaginationTable } from "../../../../Ui/Table"
import { Avatar } from "@mui/material"
import { Site } from "../../../../../Types/Site"
import { Message } from "../../../../Ui/Message"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const SiteUserList: FC = () => {
    const { state: message } = useLocation()

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [site, setSite] = useState<Site>()


    useEffect(() => {
        const getData = async (id: string) => {
            const site = await getSite(id)
            setSite(site)
        }

        getData(id)
    }, [])


    // データ取得
    const { isPending, data } = useSiteUserList(id)

    const columnHelper = createColumnHelper<SiteUser>()
    const columns = setColumns(columnHelper)

    if (isPending) return <Loading isLoading={isPending} />
    return (
        <>
            <Message message={message} />
            <H1 className="mb-12" icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}ユーザー共有設定）</H1>
            <PaginationTable data={data} columns={columns}>
                <AddButton path={`/sites/site_users/create?id=${id}`} className="ml-2" />
            </PaginationTable>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<SiteUser>) => {
    return [
        columnHelper.accessor('user.name', {
            header: '氏名',
            cell: (props) => (
                <>
                    <div className="flex items-center">
                        <Avatar 
                            alt={props.row.original.user.name} 
                            sx={{ width: 36, height: 36, display: "inline-flex", marginRight: 1 }} 
                            src={props.row.original.user.logo}
                        />
                        {props.row.original.user.name}
                    </div>
                </>
            )
        }),
        columnHelper.accessor('user.email', {
            header: 'メールアドレス'
        }),
        columnHelper.accessor('role.name', {
            header: '権限'
        }),
        // columnHelper.display({
        //     id: 'action',
        //     header: '',
        //     cell: (props) => (
        //         <>
        //             <Link to={`/sites/edit?id=${props.row.original.id}`}><Button>編集</Button></Link>
        //         </>
        //     )
        // })
    ]
}