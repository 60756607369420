import { FC, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { MainContent } from "../../../Ui/MainContent"
import { Input, Label } from "../../../Ui/Form"
import { SubmitButton } from "../../../Ui/Button"
import { GAPropertySelect } from "../../../Molecules/GAPropertySelect"
import { getGAPropertyBySiteId, updateGAProperty } from "../../../../Hooks/User/GAProperty"
import { GAProperty } from "../../../../Types/GAProperty"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const GASetting: FC = () => {

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)

    const useFormMethods = useForm<GAProperty>()
    const { handleSubmit, setValue, setError } = useFormMethods

    const onSubmit: SubmitHandler<GAProperty> = (data) => {
        setIsLoading(true)

        updateGAProperty(data)
            .then(() => {
                navigate('/sites', { state: 'GA4連携が完了しました' })
            })
            .catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.name) setError('name', { type: 'custom', message: errors.name.join('/') })
                if (errors.property_id) setError('property_id', { type: 'custom', message: errors.property_id.join('/') })
            })
    }

    
    useEffect(() => {
        const getData = async (id: string) => {
            const data = await getGAPropertyBySiteId(id)

            setValue('id', data.id)
            setValue('name', data.name)
            setValue('property_id', data.property_id)

            setIsLoading(false)
        }

        getData(id)
    }, [])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<LanguageIcon />}>サイト設定</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input name="id" type="hidden" disabled={true} />

                        <Label isRequire={true}>プロパティ</Label>
                        <GAPropertySelect site_id={id} name="property_id" labelName="name" />

                        <div className="mt-8">             
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}