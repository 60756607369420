
import { Select } from "../Ui/Form"

export const MonthlyTargetYearSelect = ({name, placeholder='年月を選択してください'}: {name: string, placeholder?: string}) => {
    const currentYear = new Date().getFullYear()
    const startYear = 2021
    const selectOptions = []

    for (let year = currentYear; year >= startYear; year--) {
        const yearString = year.toString()
        selectOptions.push({ value: yearString, label: yearString })
    }

    return (
        <Select name={name} options={selectOptions} placeholder={placeholder} />
    )
}