import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllChannelGroupLog, SearchAllChannelGroupLog } from '../../Types/AllChannelGroupLog'


export const getAllChannelGroupLogList = async (searchParams: SearchAllChannelGroupLog | undefined) => {
    const { data } = await axios.get('/api/grouped_ga_channel_group_logs', { params: searchParams })
    return data
}

export const useAllChannelGroupLogList = (searchParams: SearchAllChannelGroupLog | undefined): UseQueryResult<AllChannelGroupLog[]> => {
    return useQuery({
        queryKey: ['getAllChannelGroupLogList', searchParams],
        queryFn: () => getAllChannelGroupLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
