import { useConversionSelectOptions } from "../../Hooks/User/Conversion"
import { MultiSelect, Select } from "../Ui/Form"

export const ConversionSelect = ({ siteId, name, placeholder='コンバージョン種別を選択してください', onChange }: { siteId: number, name: string, placeholder?: string, onChange?: (newValue: number | string) => void }) => {
    const { options, isLoading, isError } = useConversionSelectOptions(siteId)

    if (isLoading) return <span className="text-xs">Loading...</span>
    if (isError) return <span className="text-xs">Error loading</span>

    return (
        <Select name={name} options={options} placeholder={placeholder} onChange={onChange} isClearable={true} />
    )
}

export const ConversionMultiSelect = ({ siteId, name, placeholder='コンバージョン種別を選択してください', onChange }: { siteId: number, name: string, placeholder?: string, onChange?: (newValue: number | string) => void }) => {
    const { options, isLoading, isError } = useConversionSelectOptions(siteId)

    if (isLoading) return <span className="text-xs">Loading...</span>
    if (isError) return <span className="text-xs">Error loading</span>

    return (
        <MultiSelect name={name} options={options} placeholder={placeholder} onChange={onChange} isClearable={true} />
    )
}