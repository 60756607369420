import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Conversion, SearchConversion } from '../../Types/Conversion'
import { useMemo } from 'react'

export const getConversion = async (id: string) => {
    const { data } = await axios.get(`/api/conversions/${id}`)
    return data
}

export const updateConversion = async (data: Conversion): Promise<AxiosResponse> => {
    return await axios.patch('/api/conversions', data)
}

export const createConversion = async (data: Conversion): Promise<AxiosResponse> => {
    return await axios.post('/api/conversions', data)
}

export const getConversionList = async (searchParams: SearchConversion | undefined) => {
    const { data } = await axios.get('/api/conversions', { params: searchParams })
    return data
}

export const useConversionList = (searchParams: SearchConversion | undefined): UseQueryResult<Conversion[]> => {
    return useQuery({
        queryKey: ['getConversionList', searchParams],
        queryFn: () => getConversionList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const useConversionSelectOptions = (site_id: number) => {
    const { data: conversionList, ...queryInfo } = useConversionList({ site_id: site_id })

    const options = useMemo(() => {
        if (!conversionList) return []

        return conversionList.map((conversion: Conversion) => (
            {
                label: conversion.name,
                value: conversion.id,
            }
        ))
    }, [conversionList])

    return { options, ...queryInfo }
}
