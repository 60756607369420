import { FC, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { MainContent } from "../../../Ui/MainContent"
import { Input, Label } from "../../../Ui/Form"
import { SubmitButton } from "../../../Ui/Button"
import { createSite } from "../../../../Hooks/User/Site"
import LanguageIcon from '@mui/icons-material/Language'
import { Site } from "../../../../Types/Site"

export const SiteCreate: FC = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const useFormMethods = useForm<Site>()
    const { handleSubmit, setError } = useFormMethods

    const onSubmit: SubmitHandler<Site> = (data) => {
        setIsLoading(true)
        
        createSite(data)
            .then(() => {
                navigate('/sites', { state: 'サイトを登録しました' })
            }).catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.name) setError('name', { type: 'custom', message: errors.name.join('/') })
                if (errors.url) setError('url', { type: 'custom', message: errors.url.join('/') })
            })
    }

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<LanguageIcon />}>サイト設定</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>                        
                        <Label isRequire={true}>サイト名</Label>
                        <Input name="name" type="text" />
                    
                        <Label isRequire={true}>URL</Label>
                        <Input name="url" type="text" />

                        <div className="mt-8">
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}