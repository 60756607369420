import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { GADeviceCategoryLog, SearchGADeviceCategoryLog } from '../../Types/GADeviceCategoryLog'


export const getGADeviceCategoryLogList = async (searchParams: SearchGADeviceCategoryLog | undefined) => {
    const { data } = await axios.get('/api/ga_device_category_logs', { params: searchParams })
    return data
}

export const useGADeviceCategoryLogList = (searchParams: SearchGADeviceCategoryLog | undefined): UseQueryResult<GADeviceCategoryLog[]> => {
    return useQuery({
        queryKey: ['getGADeviceCategoryLogList', searchParams],
        queryFn: () => getGADeviceCategoryLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
