import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from "react";
import { User } from "../Types/User";
import { authUser as useAuthUser } from "../Hooks/User/Auth";

// Contextを宣言（初期値はundefined）
export const AuthContext = createContext<User|undefined>(undefined)
export const SetAuthContext = createContext<Dispatch<SetStateAction<User>>>(() => undefined)

export const AuthProvider = ({ children }: { children?: ReactNode}) => {
    const [authUser, setAuthUser] = useState<any | undefined>(undefined)

    useEffect(() => {
        const checkLogin = async () => {
            const user = await useAuthUser()
            setAuthUser(user?.data)
        }
        checkLogin()
    }, [])

    return (
        <AuthContext.Provider value={authUser}>
            <SetAuthContext.Provider value={setAuthUser}>
                {children}
            </SetAuthContext.Provider>
        </AuthContext.Provider>
    )
}