import { FC } from "react";
import { H1 } from "../../Ui/H1";
import DashboardIcon from '@mui/icons-material/Dashboard'
import { useSite } from "../../../Providers/Site";
import { Message } from "../../Ui/Message";
import { MonthlyChannelBarChart, MonthlyConversionBarChart, MonthlyConversionRateAreaChart, MonthlyDeviceConversionPieChart, MonthlyDeviceSessionPieChart, MonthlyFormCompletionRateAreaChart, MonthlyFormTransitionRateAreaChart, MonthlyPageViewAreaChart, MonthlySessionBarChart } from "../../Molecules/Dashboard";
import { Card, CardContent, Typography } from "@mui/material";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export const Dashboard: FC = () => {
    const { siteId } = useSite()

    if (!siteId) return <Message message="ヘッダーのプルダウンより対象のサイトを選択してください" />
    return (
        <div>
            <H1 icon={<DashboardIcon />}>dashboard</H1>
            <div className="mt-4 grid grid-cols-4 gap-4">
                <MonthlySessionBarChart />
                <MonthlyConversionBarChart />

                <div className="grid grid-rows-2 gap-4">
                    <MonthlyFormTransitionRateAreaChart />
                    <MonthlyConversionRateAreaChart />
                </div>
                <div className="grid grid-rows-2 gap-4">
                    <MonthlyFormCompletionRateAreaChart />
                    <MonthlyPageViewAreaChart />
                </div>
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
                <MonthlyChannelBarChart />
                <div className="grid grid-cols-2 gap-4">
                    <MonthlyDeviceSessionPieChart />
                    <MonthlyDeviceConversionPieChart />
                </div>
            </div>
        </div>
    );
}


const CustomAreaChart = ({title, value}: {title: string, value: string}) => {
    const data = [
        { x: 1, y: 2 },
        { x: 2, y: 5.5 },
        { x: 3, y: 2 },
        { x: 4, y: 8.5 },
        { x: 5, y: 1.5 },
        { x: 6, y: 5 },
        { x: 7, y: 2 },
        { x: 8, y: 5.5 },
        { x: 9, y: 2 },
        { x: 10, y: 8.5 },
        { x: 11, y: 1.5 },
        { x: 12, y: 5 }
    ]

    return (
        <Card style={{ width: '100%', height: 200 }}>
            <CardContent style={{ width: '100%', height: 150 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 777 }}>{title}</Typography>
                <Typography sx={{ fontSize: 36, fontWeight: 777, textAlign: 'center' }}>{value}</Typography>
                <ResponsiveContainer width="100%" height="100%" className="-mt-8">
                    <AreaChart data={data}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="10%" stopColor="#1567db" stopOpacity={0.3}/>
                                <stop offset="90%" stopColor="#1567db" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="x" hide={true} />
                        <YAxis hide={true} />
                        <Tooltip />
                        <Area type="monotone" dataKey="y" stroke="#1567db" fillOpacity={1} fill="url(#colorUv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    )
}