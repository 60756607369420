import { FC, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import LanguageIcon from '@mui/icons-material/Language'
import { SiteUser } from "../../../../../Types/SiteUser"
import { createSiteUser } from "../../../../../Hooks/User/SiteUser"
import { Loading } from "../../../../Ui/Loading"
import { H1 } from "../../../../Ui/H1"
import { MainContent } from "../../../../Ui/MainContent"
import { Input, Label } from "../../../../Ui/Form"
import { SubmitButton } from "../../../../Ui/Button"
import { getSite } from "../../../../../Hooks/User/Site"
import { Page404 } from "../../Page404"
import { Site } from "../../../../../Types/Site"
import { UserSelect } from "../../../../Molecules/UserSelect"
import { RoleSelect } from "../../../../Molecules/RoleSelect"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const SiteUserCreate: FC = () => {
    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404/>

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [site, setSite] = useState<Site>()

    const useFormMethods = useForm<SiteUser>()
    const { handleSubmit, setError, setValue } = useFormMethods

    const onSubmit: SubmitHandler<SiteUser> = (data) => {
        setIsLoading(true)

        createSiteUser(data)
            .then(() => {
                navigate(`/sites/site_users?id=${id}`, { state: '共有ユーザーを追加しました' })
            }).catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.user_id) setError('user_id', { type: 'custom', message: errors.user_id.join('/') })
                if (errors.role_id) setError('role_id', { type: 'custom', message: errors.role_id.join('/') })
            })
    }

    useEffect(() => {
        const getData = async (id: string) => {
            // サイト情報（H1に使用）
            const site = await getSite(id)
            setSite(site)
            setValue('site_id', site.id)
        }

        getData(id)
    }, [])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}ユーザー共有設定）</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>                        
                        <Label isRequire={true}>共有ユーザー</Label>
                        <UserSelect name="user_id" placeholder="共有したいユーザーを選択してください" />
                    
                        <Label isRequire={true}>権限</Label>
                        <RoleSelect name="role_id" placeholder="権限を選択してください" />

                        <Input type="hidden" name="site_id" />

                        <div className="mt-8">
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}