import { Outlet, ScrollRestoration } from "react-router-dom"
import { Header } from './Header'
import { SideNavi } from './SideNavi'
import { Footer } from './Footer'

export const Layout = () => {
    return (
        <>
            <div className="dark:bg-gray-900 antialiased">
                <Header />
                <div className="w-full mx-auto max-w-8xl">
                    <div className="lg:flex">
                        <SideNavi />
                        <main id="content-wrapper" className="flex-auto pl-4 pr-4 w-full min-w-0 lg:static lg:max-h-full lg:overflow-visible">
                            <div className="flex w-full relative">
                                <div className="flex-auto min-w-0 pt-6 lg:pt-8 pb:12 xl:pb-24 lg:pb-16">
                                    <div className="pb-4 mb-8 relative">
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <ScrollRestoration />
            </div>
            <Footer />    
        </>
    )
}