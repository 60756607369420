import { ColumnHelper } from "@tanstack/react-table"

// 渡したconversionsの数だけカラムを追加する
export const createConversionColumns = <T extends Record<string, unknown>>(
    columnHelper: ColumnHelper<T>,
    conversions: { [key: string]: string }
) => {
    return Object.entries(conversions).map(([key, label]) => 
        columnHelper.accessor(`CV_${key}` as keyof T, {
            header: `CV(${label})`,
            cell: (props) => (
                <div className="text-right">
                    {props.row.original[`CV_${key}` as keyof T]?.toLocaleString() || '0'}
                </div>
            ),
            meta: { isSummable: true }
        })
    )
}