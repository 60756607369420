
export const CHANNEL_GROUPS = [
    '自然検索',
    '有料検索',
    'クロスネットワーク',
    'ショッピング広告',
    'ディスプレイ',
    'ディスプレイ/SNS広告',
    'アフィリエイト広告',
    'その他広告',
    '外部サイト',
    'ソーシャル',
    'メール/通知',
    'その他',
]

export const DEVICE_CATEGORIES = [
    'PC',
    'モバイル',
    'タブレット',
    'その他',
]

// ReactEChartsにて上位以外をまとめる際の閾値
export const CHART_LIMIT = 10