import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { PageType } from '../../Types/PageType'

export const getPageTypeList = async () => {
    const { data } = await axios.get('/api/page_types')
    return data
}

// use react-query
export const usePageTypeList = (): UseQueryResult<PageType[]> => {
    return useQuery({
        queryKey: ['pageTypeList'],
        queryFn: getPageTypeList,
    })
}

export const usePageTypeSelectOptions = () => {
    const { data: pageTypeList, ...queryInfo } = usePageTypeList()

    const selectOptions = useMemo(() => {
        if (!pageTypeList) return []

        return pageTypeList.map((pageType: PageType) => (
            {
                value: pageType.id,
                label: pageType.name,
            }
        ))
    }, [pageTypeList])

    return { selectOptions, ...queryInfo }
}