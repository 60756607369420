import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyChannelGroupLog, SearchMonthlyChannelGroupLog, ThisMonthChannelGroupLog } from '../../Types/MonthlyChannelGroupLog'


export const getMonthlyChannelGroupLogList = async (searchParams: SearchMonthlyChannelGroupLog | undefined) => {
    const { data } = await axios.get('/api/monthly_channel_group_logs', { params: searchParams })
    return data
}

export const useMonthlyChannelGroupLogList = (searchParams: SearchMonthlyChannelGroupLog | undefined): UseQueryResult<MonthlyChannelGroupLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyChannelGroupLogList', searchParams],
        queryFn: () => getMonthlyChannelGroupLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getThisMonthChannelGroupLogList = async (searchParams: SearchMonthlyChannelGroupLog | undefined) => {
    const { data } = await axios.get('/api/this_month_channel_group_logs', { params: searchParams })
    return data
}

export const useThisMonthChannelGroupLogList = (searchParams: SearchMonthlyChannelGroupLog | undefined): UseQueryResult<ThisMonthChannelGroupLog> => {
    return useQuery({
        queryKey: ['getThisMonthChannelGroupLogList', searchParams],
        queryFn: () => getThisMonthChannelGroupLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}