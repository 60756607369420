import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../../Ui/Loading"
import { H1 } from "../../../../Ui/H1"
import { MainContent } from "../../../../Ui/MainContent"
import { ImageUpload, Input, Label, Radio } from "../../../../Ui/Form"
import { SubmitButton } from "../../../../Ui/Button"
import { User } from "../../../../../Types/User"
import { getUser, updateUser } from "../../../../../Hooks/User/User"
import { AuthContext, SetAuthContext } from "../../../../../Providers/UserAuth"
import { AxiosResponse } from "axios"
import { Site } from "../../../../../Types/Site"
import { getSite, updateSite } from "../../../../../Hooks/User/Site"
import { CreateMonthlyTarget } from "../../../../../Types/MonthlyTarget"
import { createMonthlyTarget } from "../../../../../Hooks/User/MonthlyTarget"
import { MonthlyTargetYearSelect } from "../../../../Molecules/MonthlyTargetYearSelect"
import { GAEventSelect } from "../../../../Molecules/GAEventSelect"
import { ConversionEventSetting } from "../../../../../Types/ConversionEventSetting"
import { createConversionEventSetting } from "../../../../../Hooks/User/ConversionEventSetting"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const ConversionEventSettingCreate: FC = () => {

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [site, setSite] = useState<Site>()

    const useFormMethods = useForm<ConversionEventSetting>()
    const { handleSubmit, setValue, setError } = useFormMethods

    const onSubmit: SubmitHandler<ConversionEventSetting> = (data) => {
        setIsLoading(true)

        createConversionEventSetting(data)
            .then(() => {
                navigate(`/sites/conversion_event_settings?id=${id}`, { state: 'CVイベントを設定しました' })
            })
            .catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.event) setError('event', { type: 'custom', message: errors.event.join('/') })
            })
    }

    useEffect(() => {
        const getData = async (id: string) => {
            const site = await getSite(id)
            setSite(site)
            setValue('site_id', site.id)

            setIsLoading(false)
        }

        getData(id)
    }, [])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}コンバージョン イベント設定）</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label isRequire={true}>イベント名</Label>
                        <div className="inline-block w-auto">
                            <GAEventSelect siteId={id} name="event" />
                        </div>
                        
                        
                        <Input name="site_id" type="hidden" />

                        <div className="mt-8">             
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}