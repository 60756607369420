
import { useRoleSelectOptions } from "../../Hooks/User/Role"
import { Select } from "../Ui/Form"

export const RoleSelect = ({name, placeholder='権限を選択してください'}: {name: string, placeholder?: string}) => {
    const { selectOptions, isLoading, isError } = useRoleSelectOptions()

    if (isLoading) return <span className="text-xs">Loading...</span>
    if (isError) return <span className="text-xs">Error loading</span>

    return (
        <Select name={name} options={selectOptions} placeholder={placeholder} />
    )
}