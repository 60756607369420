import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllDeviceCategoryLog, SearchAllDeviceCategoryLog } from '../../Types/AllDeviceCategoryLog'


export const getAllDeviceCategoryLogList = async (searchParams: SearchAllDeviceCategoryLog | undefined) => {
    const { data } = await axios.get('/api/grouped_ga_device_category_logs', { params: searchParams })
    return data
}

export const useAllDeviceCategoryLogList = (searchParams: SearchAllDeviceCategoryLog | undefined): UseQueryResult<AllDeviceCategoryLog[]> => {
    return useQuery({
        queryKey: ['getAllDeviceCategoryLogList', searchParams],
        queryFn: () => getAllDeviceCategoryLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
